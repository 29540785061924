import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Footer from "./footer";
import Header from './header';

const LandingLayout = ({ children, className }) => {

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const { error, isAuthenticated } = useAuth0();
    const [loggedIn, setLoggedIn] = useState(false)


    useEffect(() => {
        setLoggedIn(!error && isAuthenticated)

    }, [isAuthenticated, error])

    return (
        <div className={`site-container ${className ? className : ''} ${loggedIn ? 'logged-in' : 'guest-user'}`}>
            <Header />
            {children}
            <Footer />
        </div>
    )

}

export default LandingLayout;