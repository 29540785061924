import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TuneIcon from "@mui/icons-material/Tune";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";
import "../../styles/components/side-drawer.scss";
import ChatSettings from "./ChatSettings";

const drawerWidth = 290;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "56px",
  [theme.breakpoints.up("sm")]: {
    width: "56px",
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideDrawer({ children, contentWithTitle, scrollable }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex" }} className="side-drawer">
      <CssBaseline />
      <Drawer
        sx={{ display: { xs: "none", md: "flex" } }}
        variant="permanent"
        open={open}
      >
        <List sx={{ padding: 0 }}>
          <ListItem disablePadding className="drawer-toggle">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={toggleDrawer}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 1.5 : "auto",
                }}
              >
                <TuneIcon />
              </ListItemIcon>
              <ListItemText
                className="toggle-icon"
                primary="Chat Settings"
                sx={{ opacity: open ? 1 : 0 }}
              />
              {open ? (
                theme.direction === "rtl" ? (
                  <ChevronRightIcon
                    sx={{
                      display: open ? "initial" : "none",
                    }}
                  />
                ) : (
                  <ChevronLeftIcon />
                )
              ) : (
                <TuneIcon
                  sx={{
                    display: open ? "initial" : "none",
                  }}
                />
              )}
            </ListItemButton>
          </ListItem>
          <Divider />
          <ChatSettings open={open} />
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        <div
          className={`main-app-content ${
            contentWithTitle ? "with-title" : ""
          } ${scrollable ? "scrollable" : ""}`}
        >
          <div className="content-wrapper">{children}</div>
        </div>
      </Box>
    </Box>
  );
}
