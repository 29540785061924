import React, {
  useCallback,
  useMemo,
  useRef,
  createContext,
  useState,
  useContext,
  useEffect,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ApiUtils from "../utils/ApiUtils";
import { SessionUtils } from "../utils/SessionUtils";

const MAX_RETRY_ATTEMPTS = 3;
const RETRY_DELAY = 1000; // 1 second

const ChatContext = createContext();

export function useChatContext() {
  return useContext(ChatContext);
}

export function ChatProvider({ children }) {
  const { user, getAccessTokenSilently, isAuthenticated, isLoading } =
    useAuth0();
  const [sessionId, setSessionId] = useState(null);
  const [files, setFiles] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [isNewSession, setIsNewSession] = useState(true);
  const [filesInfo, setFilesInfo] = useState({
    totalFiles: 0,
    uploadedFiles: 0,
    uploadingFiles: false,
  });
  const [accessToken, setAccessToken] = useState(null);
  //Chat History Vars
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [historyError, setHistoryError] = useState(null);
  const totalItems = useMemo(
    () => chatHistory.reduce((total, group) => total + group.items.length, 0),
    [chatHistory]
  );
  //Chat History Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const historyRetryCount = useRef(0);
  const rowsPerPage = 5;
  const totalPages = Math.ceil(totalItems / rowsPerPage);
  const startIndex = currentPage * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const itemsToDisplay = SessionUtils.paginateItems(
    chatHistory,
    startIndex,
    endIndex
  );
  const historyCallCompleted = useRef(false);

  //Chat History Logic
  const fetchChatHistory = useCallback(async () => {
    if (
      historyCallCompleted.current ||
      historyRetryCount.current >= MAX_RETRY_ATTEMPTS ||
      !user
    ) {
      if (historyRetryCount.current >= MAX_RETRY_ATTEMPTS) {
        setHistoryError(
          "Max retry attempts reached for chat history. Please try again later."
        );
      }
      return;
    }

    setIsLoadingHistory(true);
    setHistoryError(null);

    try {
      const accessToken = await getAccessTokenSilently();
      const chatHistoryResponse = await ApiUtils.fetchChatHistoryList(
        user,
        accessToken
      );
      const formattedChatHistory = SessionUtils.formatSessionListData(
        chatHistoryResponse.sessions
      );
      setChatHistory(formattedChatHistory);
      historyCallCompleted.current = true;
    } catch (error) {
      console.error("Error fetching chat history:", error);
      historyRetryCount.current += 1;
      if (historyRetryCount.current < MAX_RETRY_ATTEMPTS) {
        setTimeout(fetchChatHistory, RETRY_DELAY);
      } else {
        setHistoryError(
          "Failed to load chat history after multiple attempts. Please try again later."
        );
      }
    } finally {
      setIsLoadingHistory(false);
    }
  }, [user, getAccessTokenSilently]);

  useEffect(() => {
    if (!historyCallCompleted.current) {
      fetchChatHistory();
    }
  }, [fetchChatHistory]);

  const retryFetchHistory = useCallback(() => {
    historyRetryCount.current = 0;
    historyCallCompleted.current = false;
    fetchChatHistory();
  }, [fetchChatHistory]);

  const signOut = () => {
    setSessionId(null);
    setFiles([]);
    setDocuments([]);
    setChatMessages([]);
    setIsNewSession(true);
    setFilesInfo({
      totalFiles: 0,
      uploadedFiles: 0,
      uploadingFiles: false,
    });
    setAccessToken(null);
    sessionStorage.removeItem("chatData");
  };

  const startNewChat = () => {
    setSessionId(null);
    setFiles([]);
    setDocuments([]);
    setChatMessages([]);
    setIsNewSession(true);
    setFilesInfo({
      totalFiles: 0,
      uploadedFiles: 0,
      uploadingFiles: false,
    });
  };

  useEffect(() => {
    // Save session data whenever the relevant state variables change
    const saveChatSession = async () => {
      if (accessToken && sessionId && !isNewSession) {
        try {
          const sessionData = {
            sessionId,
            documents,
            chatMessages,
            filesInfo,
          };
          await ApiUtils.saveChatSession(sessionData, accessToken);
        } catch (error) {
          console.error("Error saving chat session:", error);
        }
      }
    };
    saveChatSession();
  }, [
    sessionId,
    documents,
    chatMessages,
    filesInfo,
    isNewSession,
    accessToken,
  ]);

  const loadChatSession = async (sessionId, accessToken) => {
    if (accessToken) {
      try {
        const responseData = await ApiUtils.loadChatSession(
          sessionId,
          accessToken
        );
        if (responseData.error) {
          throw new Error(responseData.error);
        }
        setSessionId(sessionId);
        setFiles(responseData.files || []);
        setDocuments(responseData.documents || []);
        setChatMessages(responseData.chatMessages || []);
        setIsNewSession(false);
        setFilesInfo(
          responseData.filesInfo || {
            totalFiles: 0,
            uploadedFiles: 0,
            uploadingFiles: false,
          }
        );
      } catch (error) {
        console.error("Error loading chat session:", error);
      }
    }
  };

  useEffect(() => {
    // Retrieve data from session storage on component mount
    const storedData = sessionStorage.getItem("chatData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      if (parsedData.sessionId === sessionId) {
        setFiles(parsedData.files);
        setDocuments(parsedData.documents);
        setChatMessages(parsedData.chatMessages);
        setIsNewSession(parsedData.isNewSession);
        setFilesInfo(parsedData.filesInfo);
      } else {
        // If session IDs don't match, start a new chat
        startNewChat();
      }
    }
  }, []);

  useEffect(() => {
    // Store data in session storage whenever it changes
    if (sessionId) {
      const data = {
        sessionId,
        files,
        documents,
        chatMessages,
        isNewSession,
        filesInfo,
      };
      sessionStorage.setItem("chatData", JSON.stringify(data));
    }
  }, [sessionId, files, documents, chatMessages, isNewSession, filesInfo]);

  useEffect(() => {
    const handleUnload = () => {
      sessionStorage.removeItem("chatData");
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  const handleShiftDown = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
  };

  const handleShiftUp = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };
  const value = {
    sessionId,
    setSessionId,
    files,
    setFiles,
    documents,
    setDocuments,
    chatMessages,
    setChatMessages,
    isNewSession,
    setIsNewSession,
    filesInfo,
    setFilesInfo,
    startNewChat,
    loadChatSession,
    accessToken,
    setAccessToken,
    signOut,
    chatHistory,
    setChatHistory,
    isLoadingHistory,
    setIsLoadingHistory,
    historyError,
    setHistoryError,
    historyCallCompleted,
    itemsToDisplay,
    totalItems,
    totalPages,
    currentPage,
    setCurrentPage,
    historyRetryCount,
    fetchChatHistory,
    handleShiftDown,
    handleShiftUp,
    retryFetchHistory,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
}
