import { createTheme, ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { CallbackPage } from "./routes/callback-page";
import Dashboard from "./routes/Dashboard";
import DocChat from "./routes/DocChat";
import FAQs from "./routes/FAQs";
import Help from "./routes/Help";
import Search from "./routes/Search";
import Profile from "./routes/Profile";
import LensCreate from "./routes/LensCreate";
import Tutorials from "./routes/Tutorials";
import LensLibrary from "./components/Lenses/LensLibrary";
import LensView from "./routes/LensView";
import LensLibraryAdmin from "./routes/LensLibraryAdmin";
import LensRequestReview from "./routes/LensRequestReview";
import EditLensRoute from "./routes/EditLens";

const theme = createTheme({
  components: {
    MuiInputBase: {
      variants: [
        {
          props: { variant: "filled" },
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
            borderColor: "#47246B",
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100,
          padding: "10px 16px",
          fontSize: "14px",
        },
      },
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            borderRadius: 4,
            padding: "5px 24px",
          },
        },
      ],
    },
  },
  palette: {
    primary: {
      main: "#47246B",
    },
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route path="/callback" element={<CallbackPage />} />
          <Route path="/tutorial" element={<Tutorials />} />
          <Route path="/chat" element={<DocChat />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/help" element={<Help />} />
          <Route path="/search" element={<Search />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/chat/lens/create" element={<LensCreate />} />
          <Route path="/lenses" element={<LensLibrary />} />
          <Route
            path="/lenses/draft"
            element={<LensLibrary section="draft" />}
          />
          <Route
            path="/lenses/personal"
            element={<LensLibrary section="personal" />}
          />
          <Route path="/lenses/review" element={<LensLibraryAdmin />} />
          <Route
            path="/lenses/request-review"
            element={<LensRequestReview />}
          />
          <Route path="/lenses/edit/:lensId" element={<EditLensRoute />} />
          <Route path="/lenses/view/:lensId" element={<LensView />} />
          <Route
            path="/lenses/request-review/:lensId"
            element={<LensRequestReview />}
          />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
