import ChatComponent from "../components/DocChat/ChatComponent";
import AppLayout from "../layouts/AppLayout";

const DocChat = () => {
    return (
        <AppLayout>
            <ChatComponent />
        </AppLayout>
    )
};

export default DocChat;