import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { IconButton, Snackbar } from "@mui/material";
import React, { useState } from "react";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import "../../styles/CopyIcon.css";
const MessageActions = ({ systemResponseText }) => {
  const [displayNotification, setDisplayNotification] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(systemResponseText);
    setDisplayNotification(true);
  };
  const handleClose = () => {
    setDisplayNotification(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="message-actions">
      <div className="actions-left">
        <IconButton onClick={handleCopy}>
          <ContentCopyIcon />
        </IconButton>
        {/** comment out for now no backend functionality yet 
        <IconButton>
          <RefreshIcon  />
        </IconButton>
        <IconButton>
          <BookmarkBorderOutlinedIcon />
        </IconButton>
        */}
      </div>
      <div className="actions-right">
        <IconButton>
          <ThumbUpIcon sx={{ color: "#47246B" }} />
        </IconButton>
        <IconButton>
          <ThumbDownIcon sx={{ color: "#47246B" }} />
        </IconButton>
      </div>
      <Snackbar
        open={displayNotification}
        autoHideDuration={5000}
        onClose={handleClose}
        message="Text copied to the clipboard"
        action={action}
      />
    </div>
  );
};

export default MessageActions;
