import ProfilePage from '../components/Profile/ProfilePage';
import AppLayout from "../layouts/AppLayout";


const Profile = () => {
    return (
        <AppLayout contentWithTitle>
            <ProfilePage />
        </AppLayout>
    )
}

export default Profile;