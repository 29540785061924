import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { Button, CircularProgress } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import tutorials from '../../data/tutorials.json';
import '../../styles/TutorialsGrid.scss';
import VideoCard from '../Tutorials/VideoCard';

function Fancybox(props) {
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;

        const delegate = props.delegate || "[data-fancybox]";
        const options = props.options || {};

        NativeFancybox.bind(container, delegate, options);

        return () => {
            NativeFancybox.unbind(container);
            NativeFancybox.close();
        };
    });



    return <div className={props.className ? props.className : ""} ref={containerRef}>{props.children}</div>;
}

const TutorialsGrid = () => {
    const { featured, feed } = tutorials
    const [page, setPage] = useState(0)
    const [loading, setLoading] = useState(false)
    const itemsPerPage = 6

    const simulateLoadMore = () => {
        setLoading(true)
        setTimeout(function () {
            setPage(prev => prev + 1)
            setLoading(false)
        }, 1000);
    }

    return (
        <section className='tutorials'>
            <span className="ornament-triangle-1"></span>
            <span className="ornament-circle-1"></span>
            <span className="ornament-circle-2"></span>
            <div className="section-container">
                {featured &&
                    <Fancybox
                        className="featured-video"
                        options={{
                            Carousel: {
                                infinite: false,
                            },
                        }}
                    >
                        <VideoCard
                            {...featured}
                            featured
                        />
                    </Fancybox>
                }
                {feed.length > 0 &&
                    <>

                        <Fancybox
                            className="videos-grid"
                            options={{
                                Carousel: {
                                    infinite: false,
                                },
                            }}
                        >
                            {feed
                                .filter((video, index) =>
                                    index < page * itemsPerPage + itemsPerPage
                                )
                                .map(video =>
                                    <VideoCard {...video} />
                                )
                            }
                        </Fancybox>

                        {feed.length > page * itemsPerPage + itemsPerPage &&
                            <Button
                                variant='contained'
                                sx={{ minWidth: 215 }}
                                onClick={simulateLoadMore}
                                disabled={loading}
                                startIcon={loading ? <CircularProgress
                                    sx={{ width: "24px" }}
                                    size="small"
                                /> : null}
                            >
                                {loading ? 'Loading' : 'Load more'}
                            </Button>}
                    </>
                }
            </div>
        </section >
    )
}

export default TutorialsGrid;