class FileUtils {
  /**
   * File Validation before sending to API
   * @param {*} event
   * @param {*} currentFiles
   * @param {*} setFilesCallback
   * @returns
   */
  static validateAndGetFiles(event, currentFiles, setFilesCallback) {
    const newFileList = event.target.files;

    // Check if files are provided.
    if (!newFileList.length) throw new Error("Please select a file.");

    // Check if the total number of files (existing + new) exceeds 3.
    if (currentFiles.length + newFileList.length > 5)
      throw new Error("You can only upload up to 5 files in total.");

    // Check the size of each new file.
    for (const file of newFileList) {
      if (file.size > 50 * 1024 * 1024) {
        throw new Error(`File ${file.name} size exceeds 50MB`);
      }
    }

    // If all validations passed, update the state with the combined files.
    const newFilesArray = Array.from(newFileList);
    const updatedFiles = [...currentFiles, ...newFilesArray];
    setFilesCallback(updatedFiles);

    return newFileList;
  }

  /**
   * Reading files from S3
   * @param {*} file
   * @returns
   */
  static readFileAsDataURL(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(new Error("Failed to read file"));
      reader.readAsDataURL(file);
    });
  }
}
export default FileUtils;
