import { useEffect } from 'react';
import Welcome from '../components/Dashboard/welcome';
import LandingLayout from '../layouts/LandingLayout';

const Dashboard = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <LandingLayout>
            <Welcome />
        </LandingLayout>
    )
}

export default Dashboard;