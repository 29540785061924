import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Divider,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";

import { Check } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/ChatComponent.scss";
import "../../styles/CreateLens.scss";
import LensContext from "../Contexts/LensContext";
import DefinitionLens from "./LensTemplates/DefinitionLens";
import OrgGoalsStrategiesLens from "./LensTemplates/OrgGoalsStrategies";
import PriorityAudienceLens from "./LensTemplates/PriorityAudience";
import PersonaLens from "./LensTemplates/Persona";
import TaskOrientationLens from "./LensTemplates/TaskOrientation";
import ContextStoriesLens from "./LensTemplates/ContextStories";
import { v4 as uuidv4 } from "uuid";

const getSteps = (lensType) => {
  if (lensType === "Definition") {
    return ["Type of Lens", "Lens Definition", "Examples"];
  } else if (lensType === "Organization Goals and Strategies") {
    return ["Type of Lens", "Org Strategies and Goals"];
  } else if (lensType === "Priority Audience") {
    return ["Type of Lens", "Audience Description", "Audience Characteristics"];
  } else if (lensType === "Persona") {
    return ["Type of Lens", "Persona Details", "Persona Characteristics"];
  } else if (lensType === "Task Orientation") {
    return ["Type of Lens", "Task Details", "Task Characteristics"];
  } else if (lensType === "Context Stories") {
    return ["Type of Lens", "Context Stories"];
  }

  return [];
};

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 30px)",
    right: "calc(50% + 30px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#47246B",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#47246B",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 1,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#47246B",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#47246B",
    zIndex: 1,
    fontSize: 15,
  },
  "& .QontoStepIcon-circle": {
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

function CreateLens() {
  const { user, getAccessTokenSilently } = useAuth0();
  const [activeStep, setActiveStep] = React.useState(0);
  const [finished, setFinised] = React.useState(false);
  const navigate = useNavigate();
  const { addLens, setAccessToken, setUserId } = useContext(LensContext);
  const [wordOrPhrase, setWordOrPhrase] = useState("");
  const [definition, setDefinition] = useState("");
  const [synonyms, setSynonyms] = useState([]);
  const [examples, setExamples] = useState([]);
  const [lensType, setLensType] = useState("Definition");
  const [goals, setGoals] = useState([]);
  const [values, setValues] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const [lensName, setLensName] = useState([]);
  const [audienceName, setAudienceName] = useState([]);
  const [audienceDescription, setAudienceDescription] = useState([]);
  const [audienceCharacteristics, setAudienceCharacteristics] = useState([]);
  const [audienceLikes, setAudienceLikes] = useState([]);
  const [audienceDislikes, setAudienceDislikes] = useState([]);
  const [audienceInteractions, setAudienceInteractions] = useState([]);
  const [personaName, setPersonaName] = useState("");
  const [personaDescription, setPersonaDescription] = useState("");
  const [personaBackground, setPersonaBackground] = useState("");
  const [personaCharacteristics, setPersonaCharacteristics] = useState("");
  const [personaValues, setPersonaValues] = useState("");
  const [communicationStyle, setCommunicationStyle] = useState("");
  const [scenarioContext, setScenarioContext] = useState("");
  const [personaDialogue, setPersonaDialogue] = useState("");
  const [taskDefinition, setTaskDefinition] = useState("");
  const [desiredOutcome, setDesiredOutcome] = useState("");
  const [challenges, setChallenges] = useState("");
  const [peopleInvolved, setPeopleInvolved] = useState("");
  const [perspective, setPerspective] = useState("");
  const [contextStory1, setContextStory1] = useState("");
  const [contextStory2, setContextStory2] = useState("");
  const [contextStory3, setContextStory3] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [authors, setAuthors] = useState("");
  const steps = getSteps(lensType);

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setAccessToken(token);
        setUserId(user.sub);
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    getAccessToken();
  }, [getAccessTokenSilently, setAccessToken]);

  const nextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
    window.scrollTo(0, 0);
  };
  const prevStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
    window.scrollTo(0, 0);
  };

  const onFinish = () => {
    const lensId = uuidv4();
    if (lensType === "Definition") {
      const newLens = {
        lens_type: "Definition",
        lensId,
        title,
        description,
        authors: authors.split(",").map((author) => author.trim()),
        word_or_phrase: wordOrPhrase,
        definition,
        synonyms,
        examples,
        type: "personal",
        version: "1.0", // Initial version
        published: new Date().toISOString(),
        favorited: false,
        likes: "0",
      };
      addLens(newLens);
      // Reset form fields
      setTitle("");
      setDescription("");
      setAuthors("");
      setWordOrPhrase("");
      setDefinition("");
      setSynonyms([]);
      setExamples([]);
    } else if (lensType === "Organization Goals and Strategies") {
      const newLens = {
        lens_type: "Organization Goals and Strategies",
        lensId,
        lensName,
        goals,
        values,
        strategies,
        title,
        description,
        authors: authors.split(",").map((author) => author.trim()),
        type: "personal",
        version: "1.0", // Initial version
        published: new Date().toISOString(),
        favorited: false,
        likes: "0",
      };
      addLens(newLens);
      setTitle("");
      setDescription("");
      setAuthors("");
      setLensName("");
      setGoals([]);
      setValues([]);
      setStrategies([]);
    } else if (lensType === "Priority Audience") {
      const newLens = {
        lens_type: "Priority Audience",
        lensId,
        audienceName,
        audienceDescription,
        audienceCharacteristics,
        audienceLikes,
        audienceDislikes,
        audienceInteractions,
        title,
        description,
        authors: authors.split(",").map((author) => author.trim()),
        type: "personal",
        version: "1.0", // Initial version
        published: new Date().toISOString(),
        favorited: false,
        likes: "0",
      };
      addLens(newLens);
      setTitle("");
      setDescription("");
      setAuthors("");
      setAudienceName("");
      setAudienceDescription([]);
      setAudienceCharacteristics([]);
      setAudienceLikes([]);
      setAudienceDislikes([]);
      setAudienceInteractions([]);
    } else if (lensType === "Persona") {
      const newLens = {
        lens_type: "Persona",
        lensId,
        personaName,
        personaDescription,
        personaBackground,
        personaCharacteristics,
        personaValues,
        communicationStyle,
        scenarioContext,
        personaDialogue,
        title,
        description,
        authors: authors.split(",").map((author) => author.trim()),
        type: "personal",
        version: "1.0", // Initial version
        published: new Date().toISOString(),
        favorited: false,
        likes: "0",
      };
      addLens(newLens);
      setTitle("");
      setDescription("");
      setAuthors("");
      setPersonaName("");
      setPersonaDescription("");
      setPersonaBackground("");
      setPersonaCharacteristics("");
      setPersonaValues("");
      setCommunicationStyle("");
      setScenarioContext("");
      setPersonaDialogue("");
    } else if (lensType === "Task Orientation") {
      const newLens = {
        lens_type: "Task Orientation",
        lensId,
        lensName,
        taskDefinition,
        desiredOutcome,
        challenges,
        peopleInvolved,
        title,
        description,
        authors: authors.split(",").map((author) => author.trim()),
        type: "personal",
        version: "1.0", // Initial version
        published: new Date().toISOString(),
        favorited: false,
        likes: "0",
      };
      addLens(newLens);
      setTitle("");
      setDescription("");
      setAuthors("");
      setLensName("");
      setTaskDefinition("");
      setDesiredOutcome("");
      setChallenges("");
      setPeopleInvolved("");
    } else if (lensType === "Context Stories") {
      const newLens = {
        lens_type: "Context Stories",
        lensId,
        lensName,
        perspective,
        contextStory1,
        contextStory2,
        contextStory3,
        title,
        description,
        authors: authors.split(",").map((author) => author.trim()),
        type: "personal",
        version: "1.0", // Initial version
        published: new Date().toISOString(),
        favorited: false,
        likes: "0",
      };
      addLens(newLens);
      setTitle("");
      setDescription("");
      setAuthors("");
      setLensName("");
      setPerspective("");
      setContextStory1("");
      setContextStory2("");
      setContextStory3("");
    }
    setActiveStep((prevStep) => prevStep + 1);
    setFinised(true);
    window.scrollTo(0, 0);
  };

  const handleClose = () => {
    navigate("/lenses");
  };

  return (
    <>
      <Box className="app-section">
        <Box className="ai-card">
          <Box className="heading">
            <h3>
              <b>Create New Lens: </b>Type of Lens
            </h3>
          </Box>
          <Box className="card-section-content">
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              connector={<QontoConnector />}
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={QontoStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <Divider />
            <Box className={`lens-creation-content step-${activeStep + 1}`}>
              {activeStep === 0 && (
                <>
                  <FormControl className="radio-buttons">
                    <FormLabel id="lens-type-label">
                      Which type of lens do you want to create?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="lens-type-label"
                      value={lensType}
                      onChange={(e) => setLensType(e.target.value)}
                      name="lens-type"
                      defaultValue="female"
                    >
                      <FormControlLabel
                        value="Definition"
                        control={<Radio />}
                        label="Definition"
                      />
                      <FormControlLabel
                        value="Organization Goals and Strategies"
                        control={<Radio />}
                        label="Organization Goals and Strategies"
                      />
                      <FormControlLabel
                        value="Priority Audience"
                        control={<Radio />}
                        label="Priority Audience"
                      />
                      <FormControlLabel
                        value="Persona"
                        control={<Radio />}
                        label="Persona"
                      />
                      <FormControlLabel
                        value="Task Orientation"
                        control={<Radio />}
                        label="Task Orientation"
                      />
                      <FormControlLabel
                        value="Context Stories"
                        control={<Radio />}
                        label="Context Stories"
                      />
                    </RadioGroup>
                  </FormControl>
                  <div className="actions">
                    <Button onClick={() => navigate("/lenses")}>Cancel</Button>
                    <Button variant="contained" onClick={nextStep}>
                      Begin
                    </Button>
                  </div>
                  <Divider />
                  <Typography className="footer-message">
                    To create additional lenses, click on “Create New Lens”
                    again.
                  </Typography>
                </>
              )}
              {(activeStep === 1 || activeStep === 2) &&
                lensType === "Definition" && (
                  <DefinitionLens
                    wordOrPhrase={wordOrPhrase}
                    setWordOrPhrase={setWordOrPhrase}
                    definition={definition}
                    setDefinition={setDefinition}
                    synonyms={synonyms}
                    setSynonyms={setSynonyms}
                    examples={examples}
                    setExamples={setExamples}
                    activeStep={activeStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    onFinish={onFinish}
                    title={title}
                    setTitle={setTitle}
                    description={description}
                    setDescription={setDescription}
                    authors={authors}
                    setAuthors={setAuthors}
                  />
                )}
              {(activeStep === 1 || activeStep === 2) &&
                lensType === "Persona" && (
                  <PersonaLens
                    personaName={personaName}
                    setPersonaName={setPersonaName}
                    personaDescription={personaDescription}
                    setPersonaDescription={setPersonaDescription}
                    personaBackground={personaBackground}
                    setPersonaBackground={setPersonaBackground}
                    personaCharacteristics={personaCharacteristics}
                    setPersonaCharacteristics={setPersonaCharacteristics}
                    personaValues={personaValues}
                    setPersonaValues={setPersonaValues}
                    communicationStyle={communicationStyle}
                    setCommunicationStyle={setCommunicationStyle}
                    scenarioContext={scenarioContext}
                    setScenarioContext={setScenarioContext}
                    personaDialogue={personaDialogue}
                    setPersonaDialogue={setPersonaDialogue}
                    activeStep={activeStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    onFinish={onFinish}
                    title={title}
                    setTitle={setTitle}
                    description={description}
                    setDescription={setDescription}
                    authors={authors}
                    setAuthors={setAuthors}
                  />
                )}
              {activeStep === 1 &&
                lensType === "Organization Goals and Strategies" && (
                  <OrgGoalsStrategiesLens
                    lensName={lensName}
                    setLensName={setLensName}
                    goals={goals}
                    setGoals={setGoals}
                    values={values}
                    setValues={setValues}
                    strategies={strategies}
                    setStrategies={setStrategies}
                    prevStep={prevStep}
                    onFinish={onFinish}
                    title={title}
                    setTitle={setTitle}
                    description={description}
                    setDescription={setDescription}
                    authors={authors}
                    setAuthors={setAuthors}
                  />
                )}
              {(activeStep === 1 || activeStep === 2) &&
                lensType === "Priority Audience" && (
                  <PriorityAudienceLens
                    audienceName={audienceName}
                    setAudienceName={setAudienceName}
                    audienceDescription={audienceDescription}
                    setAudienceDescription={setAudienceDescription}
                    audienceCharacteristics={audienceCharacteristics}
                    setAudienceCharacteristics={setAudienceCharacteristics}
                    audienceLikes={audienceLikes}
                    setAudienceLikes={setAudienceLikes}
                    audienceDislikes={audienceDislikes}
                    setAudienceDislikes={setAudienceDislikes}
                    audienceInteractions={audienceInteractions}
                    setAudienceInteractions={setAudienceInteractions}
                    activeStep={activeStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    onFinish={onFinish}
                    title={title}
                    setTitle={setTitle}
                    description={description}
                    setDescription={setDescription}
                    authors={authors}
                    setAuthors={setAuthors}
                  />
                )}
              {(activeStep === 1 || activeStep === 2) &&
                lensType === "Task Orientation" && (
                  <TaskOrientationLens
                    lensName={lensName}
                    setLensName={setLensName}
                    taskDefinition={taskDefinition}
                    setTaskDefinition={setTaskDefinition}
                    desiredOutcome={desiredOutcome}
                    setDesiredOutcome={setDesiredOutcome}
                    challenges={challenges}
                    setChallenges={setChallenges}
                    peopleInvolved={peopleInvolved}
                    setPeopleInvolved={setPeopleInvolved}
                    activeStep={activeStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    onFinish={onFinish}
                    title={title}
                    setTitle={setTitle}
                    description={description}
                    setDescription={setDescription}
                    authors={authors}
                    setAuthors={setAuthors}
                  />
                )}
              {activeStep === 1 && lensType === "Context Stories" && (
                <ContextStoriesLens
                  lensName={lensName}
                  setLensName={setLensName}
                  perspective={perspective}
                  setPerspective={setPerspective}
                  contextStory1={contextStory1}
                  setContextStory1={setContextStory1}
                  contextStory2={contextStory2}
                  setContextStory2={setContextStory2}
                  contextStory3={contextStory3}
                  setContextStory3={setContextStory3}
                  activeStep={activeStep}
                  nextStep={nextStep}
                  prevStep={prevStep}
                  onFinish={onFinish}
                  title={title}
                  setTitle={setTitle}
                  description={description}
                  setDescription={setDescription}
                  authors={authors}
                  setAuthors={setAuthors}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={finished}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Your lens has been created successfully."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You can now access and use your new lens through the left column
            Menu under the Lenses Category.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            flexFlow: {
              xs: "column",
              sm: "initial",
              md: "initial",
              xl: "initial",
            },
          }}
        >
          <Button onClick={handleClose}>return to chat</Button>
          <Button onClick={handleClose} autoFocus variant="contained">
            Create another lens
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CreateLens;
