import React, { useEffect, useState, useCallback, useContext } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import LanguageIcon from "@mui/icons-material/Language";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ShareIcon from "@mui/icons-material/Share";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import {
  Button,
  CircularProgress,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MoreOptionsMenu } from "../Menus/MoreOptionsMenu";
import LensContext from "../Contexts/LensContext";

const LensCard = ({
  tag,
  info,
  admin,
  onSelectAction,
  selected,
  pseudoId,
  onDeny,
  onApprove,
}) => {
  const navigate = useNavigate();
  const { deleteLens, addToLibrary, removeFromLibrary, toggleFavorite } =
    useContext(LensContext);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const {
    title,
    description,
    authors,
    published,
    //files,
    lensId,
    type,
    status,
    version,
    favorited,
    likes,
    lens_type,
  } = info;

  const [isFavorited, setIsFavorited] = useState(info.favorited);
  const [isInLibrary, setIsInLibrary] = useState(info.status === "in_library");

  useEffect(() => {
    setIsFavorited(info.favorited);
    setIsInLibrary(info.status === "in_library");
  }, [info.favorited, info.status]);

  const handleLibraryAction = async () => {
    const action = isInLibrary ? removeFromLibrary : addToLibrary;
    const libraryStatus = await action(info.lensId);
    info.status = libraryStatus;
    console.log(isInLibrary);
  };

  const handleToggleFavorite = async () => {
    try {
      const favStatus = await toggleFavorite(lensId);
      info.favorited = favStatus;
    } catch (error) {
      console.error("Error toggling favorite:", error);
    }
  };

  const handleChange = (event) => {
    onSelectAction(pseudoId, event.target.checked ? "select" : "deselect");
  };

  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
  };

  const handleEditClick = () => {
    navigate(`/lenses/edit/${lensId}`);
  };

  const handleConfirmDelete = () => {
    deleteLens(lensId);
    setOpenDeleteDialog(false);
  };

  const formatDateToReadable = (dateString) => {
    const date = new Date(dateString);

    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    const day = date.getDate();
    const daySuffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return formattedDate.replace(/(\d+)(,)/, `$1${daySuffix(day)},`);
  };
  return (
    <Card className="lens-card">
      <CardContent>
        <div className="upperGroup">
          <div className="card-header">
            {!admin && (
              <span className={`tag ${type}`}>
                {type === "personal" && <PersonOutlineIcon />}
                {type === "featured" && <WhatshotIcon />}
                {type === "community" && <LanguageIcon />} {type}
              </span>
            )}
            {admin && (
              <FormControlLabel
                control={
                  <Checkbox checked={selected} onChange={handleChange} />
                }
                sx={{ marginRight: 0 }}
              />
            )}
            <MoreOptionsMenu
              onDeleteLens={handleDeleteClick}
              onEditLens={handleEditClick}
              className="grid-view-menu"
            />
          </div>
          <Divider />
          <div
            className="card-body"
            onClick={() =>
              navigate(
                admin
                  ? `/lenses/request-review/${lensId}`
                  : `/lenses/view/${lensId}`
              )
            }
          >
            <div className="tag-version">
              <span className={`tag ${type} show-list`}>
                {type === "personal" && <PersonOutlineIcon />}
                {type === "featured" && <WhatshotIcon />}
                {type === "community" && <LanguageIcon />} {type}
              </span>
            </div>
            <span className="version">{lens_type}</span>
            <Typography component="h7">
              {title}
              <span className="version"> V {version}</span>
            </Typography>
            <Typography component="p">{description}</Typography>
          </div>
        </div>
        <div
          className="lowerGroup"
          onClick={() =>
            navigate(
              admin
                ? `/lenses/request-review/${lensId}`
                : `/lenses/view/${lensId}`
            )
          }
        >
          {/*
        <div className="lowerGroup" onClick={handleEditClick}>
        */}
          <Divider />
          <div className="card-info">
            <Typography>
              <PeopleOutlineIcon /> {authors}
            </Typography>
            <Typography>
              <CalendarTodayIcon /> published {formatDateToReadable(published)}
            </Typography>
            {/*
            <Typography>
              <FolderOpenIcon /> {files.length} file
              {files.length > 1 ? "s" : ""}
            </Typography>
            */}
          </div>
          <Divider />
        </div>
      </CardContent>
      <CardActions>
        {!admin && (
          <>
            <div className="actions">
              <Button
                key={`library-${info.lensId}`}
                onClick={() => {
                  setIsInLibrary((prev) => !prev);
                  handleLibraryAction();
                }}
              >
                {isInLibrary ? (
                  <RemoveCircleIcon style={{ color: "#f44336" }} />
                ) : (
                  <AddCircleIcon />
                )}
              </Button>
              <Button
                key={lensId}
                onClick={() => {
                  setIsFavorited((prev) => !prev);
                  handleToggleFavorite();
                }}
              >
                {isFavorited ? <StarIcon /> : <StarBorderIcon />}
              </Button>
              {/*
              <Button>
                <ShareIcon />
              </Button>
              */}
              <MoreOptionsMenu
                onDeleteLens={handleDeleteClick}
                onEditLens={handleEditClick}
                className="list-view-menu show-list"
              />
            </div>
            {/*<Button startIcon={<RemoveRedEyeIcon />}>Try Lens</Button>*/}
          </>
        )}
        {admin && (
          <>
            <Button startIcon={<CloseIcon />} color="error" onClick={onDeny}>
              Deny
            </Button>
            <Button startIcon={<CheckIcon />} onClick={onApprove}>
              Approve
            </Button>
          </>
        )}
      </CardActions>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the lens "{title}"? This action
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default LensCard;
