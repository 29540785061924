import React from "react";

import "../../styles/Profile.scss";
import { Box, Tab, Tabs } from "@mui/material";
import BasicProfile from "./BasicProfile";
import Password from "./Password";
import PlanAndBillings from "./PlanAndBillings";

const ProfilePage = () => {
  const [currentTab, setCurrentTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const tabsContent = [<BasicProfile />, <Password />, <PlanAndBillings />];

  return (
    <section className="profile">
      <h1>Profile</h1>
      <Box sx={{ bgcolor: "background.paper" }} className="profile-container">
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons={false}
          aria-label="scrollable prevent tabs"
        >
          <Tab label="Basic" />
          <Tab label="Password" />
          {/* Hide until plan and billing is needed
                    <Tab label="Plan and Billings" />
                    */}
        </Tabs>
        {tabsContent
          .filter((tab, index) => index === currentTab)
          .map((tab) => tab)}
      </Box>
    </section>
  );
};

export default ProfilePage;
