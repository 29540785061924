// PromptQueryBox.jsx
import React from "react";
import {
  Box,
  Button,
  FormControl,
  FilledInput,
  InputLabel,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import FileUploadIcon from "@mui/icons-material/FileUpload";

function PromptQueryBox({
  query,
  handleQueryChange,
  handleQuerySubmission,
  handleFileChange,
  loading,
}) {
  return (
    <Box className="input-group" sx={{ padding: { xs: "16px", md: "0px" } }}>
      <FormControl variant="filled">
        <InputLabel htmlFor="prompt-query">
          <Typography
            sx={{ display: { xs: "flex", md: "none" }, fontSize: "16px" }}
            variant="caption"
          >
            Ask your question
          </Typography>
          <Typography
            sx={{ display: { xs: "none", md: "flex" }, fontSize: "16px" }}
            variant="caption"
          >
            What would you like to know?
          </Typography>
        </InputLabel>
        <FilledInput
          id="prompt-query"
          type="text"
          value={query}
          onChange={handleQueryChange}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleQuerySubmission();
            }
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="upload document file"
                edge="end"
                component="label"
                disabled={loading}
                color="primary"
              >
                <FileUploadIcon />
                <input
                  type="file"
                  hidden
                  accept=".pdf"
                  onChange={handleFileChange}
                  multiple
                />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <Button
        variant="contained"
        type="button"
        aria-label="send"
        onClick={handleQuerySubmission}
        disabled={loading}
      >
        <SendIcon />
      </Button>
    </Box>
  );
}

export default PromptQueryBox;
