import React, {
  useMemo,
  useRef,
  createContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ApiUtils from "../utils/ApiUtils";

const MAX_RETRY_ATTEMPTS = 3;
const RETRY_DELAY = 1000; // 1 second

const LensContext = createContext();

export const LensProvider = ({ children }) => {
  const { user, getAccessTokenSilently, isAuthenticated, isLoading } =
    useAuth0();
  const [lenses, setLenses] = useState([]);
  const [selectedLenses, setSelectedLenses] = useState([]);
  const [accessToken, setAccessToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [removedLensId, setRemovedLensId] = useState(null);
  const [addedLensId, setAddedLensId] = useState(null);
  const [lensesExpanded, setLensesExpanded] = useState(true);
  const [isLoadingLenses, setIsLoadingLenses] = useState(false);
  const [lensesError, setLensesError] = useState(null);
  const lensesRetryCount = useRef(0);
  const isFetchingLenses = useRef(false);

  const updateSelectedLenses = useCallback((lens, isSelected) => {
    setSelectedLenses((prev) => {
      if (isSelected) {
        return [...prev, lens];
      } else {
        return prev.filter((l) => l.lensId !== lens.lensId);
      }
    });
  }, []);

  const fetchLenses = useCallback(async () => {
    if (isFetchingLenses.current) return;
    if (!user) {
      lensesRetryCount.current += 1;
      if (lensesRetryCount.current < MAX_RETRY_ATTEMPTS) {
        setTimeout(fetchLenses, RETRY_DELAY);
        return;
      }
    }
    if (lensesRetryCount.current >= MAX_RETRY_ATTEMPTS) {
      setLensesError(
        "Max retry attempts reached for lenses. Please try again later."
      );
      return;
    }

    isFetchingLenses.current = true;
    setIsLoadingLenses(true);
    setLensesError(null);

    try {
      const accessToken = await getAccessTokenSilently();
      const lensesResponse = await ApiUtils.getLenses(user.sub, accessToken);
      setLenses(lensesResponse || []);
      setAccessToken(accessToken);
      setUserId(user.sub);
      lensesRetryCount.current = 0; // Reset retry count on success
    } catch (error) {
      console.error("Error fetching lenses:", error);
      lensesRetryCount.current += 1;
      if (lensesRetryCount.current < MAX_RETRY_ATTEMPTS) {
        setTimeout(fetchLenses, RETRY_DELAY);
      } else {
        setLensesError(
          "Failed to load lenses after multiple attempts. Please try again later."
        );
      }
    } finally {
      setIsLoadingLenses(false);
      isFetchingLenses.current = false;
    }
  }, [user, getAccessTokenSilently, setAccessToken, setUserId, setLenses]);

  const handleUpdateSelectedLenses = useCallback(
    (lens, isChecked) => {
      updateSelectedLenses(lens, isChecked);
    },
    [updateSelectedLenses]
  );
  const retryFetchLenses = useCallback(() => {
    lensesRetryCount.current = 0;
    isFetchingLenses.current = false;
    fetchLenses();
  }, [fetchLenses]);

  const resetRemovedLensId = useCallback(() => {
    setRemovedLensId(null);
  }, []);

  const resetAddedLensId = useCallback(() => {
    setAddedLensId(null);
  }, []);

  useEffect(() => {
    if (
      isAuthenticated &&
      user &&
      lenses.length === 0 &&
      !isFetchingLenses.current
    ) {
      fetchLenses();
    }
  }, [isAuthenticated, user, fetchLenses, lenses.length]);

  useEffect(() => {
    if ((removedLensId || addedLensId) && !isFetchingLenses.current) {
      fetchLenses();
    }
    resetRemovedLensId();
    resetAddedLensId();
  }, [
    removedLensId,
    addedLensId,
    resetRemovedLensId,
    resetAddedLensId,
    fetchLenses,
  ]);

  const groupedLenses = useMemo(() => {
    return (lenses || []).reduce((acc, lens) => {
      if (lens.status === "in_library") {
        const lensType = lens.lens_type;
        if (
          [
            "Definition",
            "Organization Goals and Strategies",
            "Priority Audience",
            "Persona",
            "Task Orientation",
            "Context Stories",
          ].includes(lensType)
        ) {
          if (!acc[lensType]) {
            acc[lensType] = [];
          }
          acc[lensType].push({
            ...lens,
            label: lens.title, // Ensure the label is set to the lens title
            lensType,
            lensIdentifier: lens.lensId, // Use lensId as the identifier
          });
        }
      }
      return acc;
    }, {});
  }, [lenses]);

  const getLensLabel = (lensType) => {
    const labels = {
      Definition: "Definition",
      "Organization Goals and Strategies": "Org Goals and Strategies",
      "Priority Audience": "Priority Audience",
      Persona: "Persona",
      "Task Orientation": "Task Orientation",
      "Context Stories": "Context Stories",
    };
    return labels[lensType] || lensType;
  };

  const handleLensesToggle = () => {
    setLensesExpanded(!lensesExpanded);
  };

  const addLens = async (lens) => {
    if (userId && accessToken) {
      try {
        const response = await ApiUtils.createLens(userId, lens, accessToken);
        setLenses((prevLenses) => [...(prevLenses || []), response]);
      } catch (error) {
        console.error("Error adding lens:", error);
      }
    }
  };

  const editLens = async (lensId, updatedLens) => {
    if (userId && accessToken) {
      try {
        await ApiUtils.updateLens(userId, lensId, updatedLens, accessToken);
        setLenses((prevLenses) =>
          prevLenses.map((lens) =>
            lens.lensId === lensId ? { ...lens, ...updatedLens } : lens
          )
        );
      } catch (error) {
        console.error("Error editing lens:", error);
      }
    }
  };

  const deleteLens = async (lensId) => {
    if (userId && accessToken) {
      try {
        await ApiUtils.deleteLens(userId, lensId, accessToken);
        setLenses((prevLenses) =>
          (prevLenses || []).filter((lens) => lens.lensId !== lensId)
        );
        setRemovedLensId(lensId);
      } catch (error) {
        console.error("Error deleting lens:", error);
      }
    }
  };

  const toggleFavorite = async (lensId) => {
    if (userId && accessToken) {
      try {
        const lens = lenses.find((lens) => lens.lensId === lensId);
        const updatedFavoriteStatus = !lens.favorited;
        await ApiUtils.updateLens(
          userId,
          lensId,
          { favorited: updatedFavoriteStatus },
          accessToken
        );
        setLenses((prevLenses) =>
          prevLenses.map((lens) =>
            lens.lensId === lensId
              ? { ...lens, favorited: updatedFavoriteStatus }
              : lens
          )
        );
        return updatedFavoriteStatus;
      } catch (error) {
        console.error("Error toggling favorite:", error);
      }
    }
  };

  const addToLibrary = async (lensId) => {
    await editLens(lensId, { status: "in_library" });
    setAddedLensId(lensId);
    return "in_library";
  };

  const removeFromLibrary = async (lensId) => {
    await editLens(lensId, { status: "not_in_library" });
    setRemovedLensId(lensId);
    return "not_in_library";
  };

  const getFavoriteLenses = () => lenses.filter((lens) => lens.favorited);

  const getLibraryLenses = () =>
    lenses.filter((lens) => lens.status === "in_library");

  return (
    <LensContext.Provider
      value={{
        lenses,
        setLenses,
        getLibraryLenses,
        selectedLenses,
        addLens,
        editLens,
        deleteLens,
        addToLibrary,
        removeFromLibrary,
        updateSelectedLenses,
        toggleFavorite,
        setAccessToken,
        setUserId,
        removedLensId,
        setRemovedLensId,
        resetRemovedLensId,
        addedLensId,
        resetAddedLensId,
        lensesExpanded,
        setLensesExpanded,
        isLoadingLenses,
        setIsLoadingLenses,
        lensesError,
        setLensesError,
        lensesRetryCount,
        isFetchingLenses,
        fetchLenses,
        retryFetchLenses,
        handleUpdateSelectedLenses,
        groupedLenses,
        getLensLabel,
        handleLensesToggle,
      }}
    >
      {children}
    </LensContext.Provider>
  );
};

export default LensContext;
