import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/ProfileMenu.scss";

const ProfileMenu = ({ onClose, onLogout, user }) => {
  const navigate = useNavigate();
  return (
    <section className="profile-menu">
      <IconButton onClick={onClose} className="close-button">
        <CloseOutlinedIcon sx={{ color: "#47246B" }} />
      </IconButton>
      <div className="menu-section menu-header">
        <div className="heading">
          <h4>{user ? user.nickname : "Lorem Ipsum"}</h4>
          <Typography
            variant="span"
            sx={{
              wordBreak: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
              whiteSpace: "nowrap",
            }}
          >
            {user ? user.email : "test@hello.com"}
          </Typography>
        </div>
        {/** Remove Account Preferences until ready
        <Button variant="outlined" onClick={() => navigate("/profile")}>
          Account Preferences
        </Button>
        */}
      </div>
      <div className="menu-section">
        <h5>Account</h5>
        <p>AI Priori, Inc.</p>
        <p>22754319</p>
      </div>
      {/* Hide until further account mgmt options are implemented
            <Divider />
            <div className="menu-section no-padding">
                <List>
                    <ListItem>
                        <ListItemButton onClick={()=>navigate('/profile')}>
                            <ListItemText>
                                <Typography>
                                    Account & Billing
                                </Typography>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton>
                            <ListItemText>
                                <Typography onClick={()=>navigate('/profile')}>
                                    Pricing & Features
                                </Typography>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton>
                            <ListItemText>
                                <Typography onClick={()=>navigate('/profile')}>
                                    Another option
                                </Typography>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
            </div>
            <Divider />
*/}
      <div className="menu-section">
        <Button variant="outlined" onClick={onLogout}>
          Log out
        </Button>
      </div>
      <Divider />
      <div className="menu-section menu-footer">
        <Button sx={{ fontSize: 12, textTransform: "none" }}>
          Privacy Policy
        </Button>
        <span className="dot-separator"></span>
        <Button sx={{ fontSize: 12, textTransform: "none" }}>
          Terms of Service
        </Button>
      </div>
    </section>
  );
};

export default ProfileMenu;
