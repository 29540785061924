import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import React from "react";
import ReactPlayer from "react-player";
import IconButton from "@mui/material/IconButton";
import "../../styles/components/how-to-use.scss";

const HowToUse = () => {
  return (
    <section className="how-to-use">
      <span className="ornament-triangle-1"></span>
      <span className="ornament-circle-1"></span>

      <div className="how-to-use-container">
        <h2>
          Learn how to use <b>AI PRIORI</b>
        </h2>
        <div className="how-to-use-content">
          <div className="text-container">
            <p>
              Watch this brief overview video showcasing how to get started
              using AI PRIORI.
            </p>
            <br></br>
            <p>
              Check out the <a href="/tutorial"> Tutorial</a> page and find
              short videos and FAQs to learn how you can maximize your use of AI
              PRIORI.
            </p>
          </div>
          <div className="video-container">
            <ReactPlayer
              url="https://youtu.be/QFO3CL1DsV0"
              width="100%"
              height="363px"
              playing
              controls
              playIcon={
                <IconButton type="button" variant="contained" aria-label="play">
                  <PlayCircleFilledWhiteOutlinedIcon />
                </IconButton>
              }
              light="../../../img/quick_start.png"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowToUse;
