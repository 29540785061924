import FileUploadIcon from "@mui/icons-material/FileUpload";
import SendIcon from "@mui/icons-material/Send";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/components/upload-pdf.scss";

const prefilled_choices = [
  "Explain health equity as if speaking to a 12 year old.",
  "How can I change a local law or policy?",
  "What are some of the root causes of food insecurity?",
];
const UploadPDF = () => {
  const [questionField, setQuestionField] = useState("");
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();

  const handleFileUpload = (e) => {
    const selectedFiles = e.target.files;
    setFiles(selectedFiles);
    navigate("/chat", { state: { files: selectedFiles } });
  };

  const handleQuerySubmission = () => {
    if (questionField !== "") {
      navigate("/chat", { state: { query: questionField } });
    }
  };

  return (
    <section className="upload-pdf">
      <span className="ornament-triangle-1"></span>
      <span className="ornament-circle-1"></span>

      <div className="upload-container">
        <div className="upload-pdf-widget">
          <div className="description">
            <p>To start, upload your document(s) or ask a question.</p>
            <span>Maximum of 5 files, 10MB each. (.pdf, .doc, .csv, etc.)</span>
          </div>
          <Button
            variant="contained"
            startIcon={<FileUploadIcon />}
            component="label"
            onChange={handleFileUpload}
          >
            select documents
            <input
              type="file"
              id="pdfFile"
              name="pdfFile"
              accept=".pdf"
              multiple
            />
          </Button>
          <Box>
            <TextField
              label={
                <>
                  <Typography
                    sx={{ display: { xs: "flex", md: "none" } }}
                    variant="caption"
                  >
                    Ask your question
                  </Typography>
                  <Typography
                    sx={{ display: { xs: "none", md: "flex" } }}
                    variant="caption"
                  >
                    What would you like to know?
                  </Typography>
                </>
              }
              variant="filled"
              value={questionField}
              autoFocus={questionField !== ""}
              onChange={(e) => setQuestionField(e.target.value)}
            />
            <IconButton
              type="button"
              aria-label="send"
              onClick={handleQuerySubmission}
            >
              <SendIcon />
            </IconButton>
          </Box>
          <p>You can also try one of these sample questions</p>
          <div className="choices-group">
            {prefilled_choices.map((choice, index) => (
              <div
                className="choice"
                key={index}
                onClick={() => setQuestionField(choice)}
              >
                {choice}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default UploadPDF;
