import React, { createContext, useContext, useState } from "react";

// Create two separate contexts: one for the value and one for the setter
const BrainstormValueContext = createContext();
const SetBrainstormValueContext = createContext();

// A provider component that encapsulates both contexts
export const BrainstormProvider = ({ children }) => {
  const [brainstormValue, setBrainstormValue] = useState(0.5);

  return (
    <BrainstormValueContext.Provider value={brainstormValue}>
      <SetBrainstormValueContext.Provider value={setBrainstormValue}>
        {children}
      </SetBrainstormValueContext.Provider>
    </BrainstormValueContext.Provider>
  );
};

// Custom hook for consuming the brainstorm value context
export const useBrainstormValue = () => {
  return useContext(BrainstormValueContext);
};

// Custom hook for consuming the setBrainstormValue context
export const useSetBrainstormValue = () => {
  return useContext(SetBrainstormValueContext);
};
