import SendIcon from "@mui/icons-material/Send";
import { Box, Button, Paper, TextField } from "@mui/material";
import AppLayout from "../layouts/AppLayout";
import "../styles/BasicForm.scss";
import "../styles/welcome.css";

const Help = () => {
  return (
    <AppLayout contentWithTitle>
      <section className="help">
        <h1>Help</h1>
        <Paper>
          <Box component="form" className="basic-form">
            <p>
              Can’t find the answer to your question on the{" "}
              <a href="/tutorial">Tutorial</a> page? Complete this form and
              we'll respond as soon as possible (usually within 24 hours).
            </p>
            <TextField variant="filled" type="email" label="From" name="from">
              {" "}
            </TextField>
            <TextField
              variant="filled"
              type="email"
              label="To"
              name="to"
              value="hello@aipriori.com"
              disabled
            ></TextField>
            <TextField
              variant="filled"
              type="text"
              label="Subject Line"
              name="subject"
              defaultValue="Help Request"
            ></TextField>
            <TextField
              variant="filled"
              multiline
              rows={5}
              fullWidth
              label="Message"
            ></TextField>
            <div className="actions">
              <Button type="submit" variant="contained" endIcon={<SendIcon />}>
                Send
              </Button>
            </div>
          </Box>
        </Paper>
      </section>
    </AppLayout>
  );
};

export default Help;
