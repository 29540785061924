import React, { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Skeleton,
  Slider,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import CameraIcon from "@mui/icons-material/Camera";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import HistoryIcon from "@mui/icons-material/History";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import "../../styles/components/side-drawer.scss";
import CollapsibleCheckbox from "./CollapsibleCheckbox";
import { useChatContext } from "../Contexts/ChatContext";
import LabelEditableListItem from "../utils/LabelEditableListItem";
import {
  useBrainstormValue,
  useSetBrainstormValue,
} from "../Contexts/BrainstormContext";
import LensContext from "../Contexts/LensContext";

const MAX_RETRY_ATTEMPTS = 3;
const RETRY_DELAY = 1000; // 1 second

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <ExpandMoreIcon {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ChatSettings({ open, onClose }) {
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

  //Brainstorm Vars
  const brainstormValue = useBrainstormValue();
  const setBrainstormValue = useSetBrainstormValue();

  const handleBrainstormChange = (event, newValue) => {
    setBrainstormValue(newValue / 10);
  };

  const {
    selectedLenses,
    lensesExpanded,
    lensesError,
    retryFetchLenses,
    handleUpdateSelectedLenses,
    groupedLenses,
    getLensLabel,
    handleLensesToggle,
    totalPages,
    currentPage,
  } = useContext(LensContext);

  const {
    loadChatSession,
    isLoadingHistory,
    historyError,
    setHistoryError,
    itemsToDisplay,
    handleShiftDown,
    handleShiftUp,
    retryFetchHistory,
  } = useChatContext();

  const handleClose = () => {
    if (onClose) onClose();
  };

  const handleOnChangeChatTitle = (item, value) => {
    // Implement chat title change logic here
  };
  const handleSessionClick = (item) => {
    getAccessTokenSilently()
      .then((accessToken) => {
        loadChatSession(item.sessionId, accessToken);
        handleClose();
      })
      .catch((error) => {
        console.error("Error getting access token:", error);
        setHistoryError("Failed to load chat session. Please try again.");
      });
  };

  return (
    <>
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          onClick={handleLensesToggle}
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 1.5 : "auto",
              justifyContent: "center",
            }}
          >
            <CameraIcon />
          </ListItemIcon>
          <ListItemText sx={{ opacity: open ? 1 : 0 }}>
            Lenses{" "}
            <Tooltip
              placement="right"
              title="Customizable filters that provide additional context to your query for AI PRIORI. Check the box next to a lens(es) to have AI PRIORI take it into consideration when generating a response."
              arrow
            >
              <InfoIcon fontSize="small" />
            </Tooltip>
          </ListItemText>
          <ListItemIcon
            sx={{
              minWidth: 0,
              display: open ? "initial" : "none",
              justifyContent: "center",
            }}
          >
            <ExpandMore
              expand={lensesExpanded}
              aria-expanded={lensesExpanded}
              aria-label="show more"
            />
          </ListItemIcon>
        </ListItemButton>
        <Collapse in={lensesExpanded && open} timeout="auto" unmountOnExit>
          <Box>
            {lensesError ? (
              <Box sx={{ p: 2 }}>
                <Typography color="error">{lensesError}</Typography>
                <Button
                  onClick={retryFetchLenses}
                  variant="outlined"
                  sx={{ mt: 1 }}
                >
                  Retry Loading Lenses
                </Button>
              </Box>
            ) : (
              <List>
                {Object.entries(groupedLenses).map(([lensType, lenses]) => (
                  <CollapsibleCheckbox
                    key={lensType}
                    label={getLensLabel(lensType)}
                    childs={lenses}
                    onChange={handleUpdateSelectedLenses}
                    selectedLenses={selectedLenses}
                  />
                ))}
              </List>
            )}
          </Box>
        </Collapse>
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 1.5 : "auto",
            justifyContent: "center",
          }}
        >
          <FlashOnIcon />
        </ListItemIcon>
        <ListItemText sx={{ opacity: open ? 1 : 0 }}>
          Brainstorm Power{" "}
          <Tooltip
            placement="right"
            title="Set the level of creativity and brainstorming you want AI PRIORI to use when generating its response. 0 = Only user content; 5 = Minimal creativity; 10 = Enhanced creativity"
            arrow
          >
            <InfoIcon fontSize="small" />
          </Tooltip>
        </ListItemText>
      </ListItem>
      <Box sx={{ padding: "0 35px", display: open ? "inherit" : "none" }}>
        <Slider
          aria-label="Brainstorm Power"
          value={brainstormValue * 10}
          onChange={handleBrainstormChange}
          step={1}
          min={1}
          max={10}
          valueLabelDisplay="auto"
          marks={[
            {
              value: 1,
              label: "Min",
            },
            {
              value: 10,
              label: "Max",
            },
          ]}
        />
      </Box>
      <Divider />
      <ListItem>
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 1.5 : "auto",
            justifyContent: "center",
          }}
        >
          <HistoryIcon />
        </ListItemIcon>
        <ListItemText sx={{ opacity: open ? 1 : 0 }}>
          Chat History{" "}
          <Tooltip placement="right" title="Your recent chat sessions" arrow>
            <InfoIcon fontSize="small" />
          </Tooltip>
        </ListItemText>
      </ListItem>
      {isLoadingHistory ? (
        <Box sx={{ padding: "16px" }}>
          <div className="loading-state">
            <CircularProgress
              sx={{ width: "24px", display: "block", marginBottom: 2 }}
              size="small"
            />
            Loading your chat history...
          </div>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton
            animation="wave"
            height={10}
            width="80%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton
            animation="wave"
            height={10}
            width="80%"
            style={{ marginBottom: 6 }}
          />
        </Box>
      ) : historyError ? (
        <Box sx={{ padding: "16px" }}>
          <Typography color="error">{historyError}</Typography>
          <Button onClick={retryFetchHistory} variant="outlined" sx={{ mt: 2 }}>
            Retry Loading History
          </Button>
        </Box>
      ) : (
        <List
          className="chat-history"
          style={{ display: open ? "flex" : "none" }}
        >
          <div className="collapse-all">
            {currentPage !== 0 && (
              <IconButton onClick={handleShiftUp}>
                <KeyboardArrowUpOutlinedIcon />
              </IconButton>
            )}
          </div>
          {itemsToDisplay.map((group, index) => (
            <div key={`group-${index}`}>
              <ListItem>
                <Typography variant="h4">{group.groupLabel}</Typography>
              </ListItem>
              {group.items.map((session, subIndex) => (
                <LabelEditableListItem
                  key={`session-${subIndex}`}
                  onClick={() => handleSessionClick(session)}
                  onChange={handleOnChangeChatTitle}
                  item={session}
                />
              ))}
            </div>
          ))}
          <div className="collapse-all">
            <IconButton
              onClick={handleShiftDown}
              disabled={currentPage >= totalPages - 1}
            >
              <KeyboardArrowDownOutlinedIcon />
            </IconButton>
          </div>
        </List>
      )}
    </>
  );
}
