import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import LanguageIcon from "@mui/icons-material/Language";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ShareIcon from "@mui/icons-material/Share";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import WhatshotIcon from "@mui/icons-material/Whatshot";

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import LensesSlider from "../components/Lenses/LensesSlider";
import RemixIcon from "../components/Icons/RemixIcon";
import AppLayout from "../layouts/AppLayout";
import "../styles/LensView.scss";
import { MoreOptionsMenu } from "../components/Menus/MoreOptionsMenu";
import LensContext from "../components/Contexts/LensContext";

const LensView = () => {
  const navigate = useNavigate();
  const { lensId } = useParams();
  const {
    lenses: contextLenses,
    deleteLens,
    addToLibrary,
  } = useContext(LensContext);
  const [lens, setLens] = useState(null);

  useEffect(() => {
    const currentLens = contextLenses.find((l) => l.lensId === lensId);
    if (currentLens) {
      setLens(currentLens);
    } else {
      // If lens is not found, navigate back to lens library
      navigate("/lenses");
    }
  }, [lensId, contextLenses, navigate]);

  const tag = "personal";

  const TruncatedTypography = styled(Typography)({
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "0.9rem",
    lineHeight: "1.2",
    textOverflow: "ellipsis",
    maxWidth: "113px",
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = (lens) => {
    setOpenDialog(true);
  };

  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
  };

  const handleEditClick = () => {
    navigate(`/lenses/edit/${lensId}`);
  };

  const handleConfirmDelete = () => {
    deleteLens(lensId);
    setOpenDeleteDialog(false);
    navigate("/lenses");
  };

  const [fav, setFav] = useState(lens?.favorited || false);

  const formatDateToReadable = (dateString) => {
    const date = new Date(dateString);

    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    const day = date.getDate();
    const daySuffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return formattedDate.replace(/(\d+)(,)/, `$1${daySuffix(day)},`);
  };

  if (!lens) {
    return null; // or a loading state if you prefer
  }

  const {
    title,
    description,
    authors,
    published,
    files,
    id,
    type,
    version,
    favorited,
  } = lens;

  const handleAddToLibrary = async () => {
    try {
      await addToLibrary(lensId);
    } catch (error) {
      console.error("Error adding lens to library:", error);
    }
  };

  return (
    <AppLayout contentWithTitle>
      <section className="lens-view">
        <Box sx={{ bgcolor: "background.paper" }} className="lenses-container">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "15px",
            }}
          >
            <Button
              startIcon={<ChevronLeftIcon />}
              onClick={() => navigate("/lenses")}
            >
              Go Back
            </Button>
            <Box sx={{ display: "flex", gap: "15px" }}>
              {/*
              <Button startIcon={<RemoveRedEyeIcon />}>Try Lens</Button>
              <Button startIcon={<RemixIcon />}>Remix</Button>
              */}
              <Button onClick={handleEditClick} startIcon={<EditIcon />}>
                Edit Lens
              </Button>
              <Button onClick={handleDeleteClick} startIcon={<DeleteIcon />}>
                Delete Lens
              </Button>
              <Button
                variant="contained"
                onClick={handleAddToLibrary}
                startIcon={<AddIcon />}
              >
                Add to my lens library
              </Button>
            </Box>
          </Box>
          <Divider />
          <Box className="lens-container">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "24px",
              }}
            >
              <span className={`tag ${type}`}>
                {type == "personal" && <PersonOutlineIcon />}
                {type == "featured" && <WhatshotIcon />}
                {type == "community" && <LanguageIcon />} {type}
              </span>
              <Box sx={{ display: "block" }}>
                {/*
                <Button onClick={() => setFav(!fav)}>
                  {fav ? <StarIcon /> : <StarBorderIcon />}
                </Button>
                <Button>
                  <ShareIcon />
                </Button>
                <MoreOptionsMenu
                  onDeleteLens={handleDeleteClick}
                  onEditLens={handleEditClick}
                />
                */}
              </Box>
            </Box>
            <Typography component="h1">{title}</Typography>
            <Box className="lens-info">
              <Typography>
                <PeopleOutlineIcon /> {authors}
              </Typography>
              <Typography>
                <CalendarTodayIcon /> published{" "}
                {formatDateToReadable(published)}
              </Typography>
              {/*
              <Typography>
                <FolderOpenIcon /> {files.length} file
                {files.length > 1 ? "s" : ""}
              </Typography>
              */}
            </Box>
            <Box className="lens-content">
              <p>{description}</p>
            </Box>
            {/*<Divider />
            <Box
              className="files-heading"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "15px 0",
              }}
            >
              <Typography>
                <FolderOpenIcon /> Documents used in this lens
                <b>({files.length})</b>
              </Typography>
              <Button startIcon={<DownloadIcon />}>
                Download all documents
              </Button>
            </Box>

            <Box className="files-container">
              {files.map((file) => (
                <Box className="file-pill">
                  <PictureAsPdfIcon />{" "}
                  <TruncatedTypography>{file}</TruncatedTypography>
                </Box>
              ))}
            </Box>
          */}
          </Box>

          <Divider />
          {/*
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "15px",
            }}
          >
            <Typography>Similar Lenses</Typography>
            <Button>View more similar lenses</Button>
          </Box>
          <Box sx={{ padding: "24px", paddingTop: "0" }}>
            <LensesSlider lenses={lenses} onAddToLibrary={handleOpenDialog} />
          </Box>
          */}
        </Box>
      </section>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"The lens has been added to your library and is ready to use."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormControlLabel
              control={<Checkbox />}
              label="Don't show this message again."
              sx={{ marginRight: 0 }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete the lens "{title}"? This action
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </AppLayout>
  );
};

export default LensView;
