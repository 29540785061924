import "../../styles/welcome.css";

import Faqs from "../Faqs/Faqs";
import faqsData from "../../data/faqs.json";
import TutorialsGrid from "./TutorialsGrid";
import WelcomeHero from "../utils/WelcomeHero";

const Tutorials = () => {
  return (
    <div className="main">
      <WelcomeHero
        title="Tutorial"
        paragraph={
          <p>
            Welcome to our tutorial page! Dive into our collection of helpful
            videos showing you how to maximize your experience and use the
            various features on the site. You’ll also find answers to commonly
            asked questions in our FAQs section below.
          </p>
        }
      />
      <TutorialsGrid />
      <Faqs content={faqsData} title="FAQs" />
    </div>
  );
};

export default Tutorials;
