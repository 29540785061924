
import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
export const SelectBox = ({
    options,
    onOptionClick,

}) => {
    const [defaultValue, setDefaultValue] = React.useState('')
    const handleChange = (event) => {
        setDefaultValue(event.target.value)
        if(onOptionClick)
            onOptionClick(event.target.value)
    }

    return (
        <>
            <FormControl sx={{ marginBottom: '24px', width: '100%' }} size="small">
                <Select
                    id="deny-reason-select"
                    value={defaultValue}
                    label=""
                    onChange={handleChange}
                >
                    {options.map(option => (
                        <MenuItem value={option}>{option}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
};