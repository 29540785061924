import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import { IconButton } from '@mui/material';
import '../../styles/components/video-card.scss';

const VideoCard = ({
    title,
    poster,
    time,
    excerpt,
    url,
    featured
}) => {
    return (
        <a
            href={url}
            data-fancybox={`tutorial-${featured ? 'featured-' : ''}videos`}
            data-caption={title}
            className={`video-card ${featured ? 'featured' : ''}`}
        >
            <div className="poster-container">
                <img
                    src={poster ? poster : "../../../img/video-poster-high.jpg"}
                    alt={title}
                />
                <IconButton type="button" variant="contained" aria-label="play">
                    <PlayCircleFilledWhiteOutlinedIcon />
                </IconButton>
            </div>
            <div className="content">
                <div className="heading">
                    <h3>
                        {title}
                    </h3>
                    <span>
                        {time}
                    </span>
                </div>
                <div className='excerpt'>
                    {excerpt}
                </div>
            </div>
        </a>
    )
}

export default VideoCard;