import React, { useEffect, useState, useContext, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  CircularProgress,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CameraIcon from "@mui/icons-material/Camera";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GridOnIcon from "@mui/icons-material/GridOn";
import ListIcon from "@mui/icons-material/List";
import SearchIcon from "@mui/icons-material/Search";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";

import LensCard from "./LensCard";
import AppLayout from "../../layouts/AppLayout";
import "../../styles/Profile.scss";
import ApiUtils from "../utils/ApiUtils";
import LensContext from "../Contexts/LensContext";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {},
  },
}));

const LensLibrary = ({ section }) => {
  const [lenses, setLenses] = useState([]);
  const [filteredLenses, setFilteredLenses] = useState([]);
  const [bestMatches, setBestMatches] = useState([]);
  const [currentSection, setCurrentSection] = useState(section);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const { user, getAccessTokenSilently, isAuthenticated, isLoading: isAuth0Loading } = useAuth0();
  const {
    setLenses: setContextLenses,
    setAccessToken,
    setUserId,
  } = useContext(LensContext);

  const getSectionIndex = (sectionName) => {
    if (sectionName == "draft") return 4;
    if (sectionName == "personal") return 2;
    return 0;
  };

  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(getSectionIndex(section));

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const types = [
    "All types",
    "Definition",
    "Organization Goals and Strategies",
    "Priority Audience",
    "Persona",
    "Task Orientation",
    "Context Stories",
  ];
  const [type, setType] = useState("All types");
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const sortings = {
    "All types": [
      //"Popularity (likes)",
      "Date (latest/oldest)",
      //"Popularity (remixed)",
      //"Bookmarked",
      //"Drafts",
      //"On review",
      //"Denied",
    ],
    // Add sorting options for each lens_type if needed
  };

  const [sorting, setSorting] = useState("");
  const handleSortingChange = (event) => {
    setSorting(event.target.value);
  };

  const [view, setView] = useState("grid");
  const handleViewChange = (val) => {
    setView(val);
  };

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedLens, setSelectedLens] = useState(null);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenDialog = (lens) => {
    setOpenDialog(true);
  };

  const fetchLenses = useCallback(async () => {
    if (isAuth0Loading || !isAuthenticated || !user) {
      return; // Exit early if Auth0 is still loading or user is not authenticated
    }

    setIsLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      const lensesResponse = await ApiUtils.getLenses(user.sub, accessToken);
      if (!lensesResponse) {
        throw new Error("No lenses data received from the server");
      }
      setLenses(lensesResponse);
      setFilteredLenses(lensesResponse);
      setContextLenses(lensesResponse);
      setAccessToken(accessToken);
      setUserId(user.sub);
      setBestMatches(lensesResponse.slice(0, 3));
    } catch (error) {
      console.error("Error fetching lenses:", error);
      setError(`Failed to load lenses: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [user, getAccessTokenSilently, setContextLenses, setAccessToken, setUserId, isAuthenticated, isAuth0Loading]);

  useEffect(() => {
    if (!isAuth0Loading && isAuthenticated) {
      fetchLenses();
    }

    const handleLensUpdate = () => {
      if (!isAuth0Loading && isAuthenticated) {
        fetchLenses();
      }
    };

    window.addEventListener("lensUpdated", handleLensUpdate);

    return () => {
      window.removeEventListener("lensUpdated", handleLensUpdate);
    };
  }, [fetchLenses, isAuthenticated, isAuth0Loading]);

  useEffect(() => {
    setCurrentTab(getSectionIndex(section));
  }, [location]);

  useEffect(() => {
    let newLenses = lenses.filter((lens) => true);
    if (type !== "All types") {
      newLenses = newLenses.filter((lens) => lens.lens_type === type);
    }

    if (
      sorting === "Popularity (likes)" ||
      sorting === "Popularity (remixed)"
    ) {
      newLenses = newLenses.sort((a, b) => b.likes - a.likes);
    } else if (sorting === "Date (latest/oldest)") {
      newLenses = newLenses.sort(
        (a, b) => new Date(b.published) - new Date(a.published)
      );
    } else if (sorting === "Bookmarked") {
      newLenses = newLenses.filter((lens) => lens.favorited);
    } else if (sorting === "Drafts") {
      newLenses = newLenses.filter((lens) => lens.status === "draft");
    } else if (sorting === "On review") {
      newLenses = newLenses.filter((lens) => lens.status === "on-review");
    } else if (sorting === "Denied") {
      newLenses = newLenses.filter((lens) => lens.status === "denied");
    }

    setFilteredLenses(newLenses);
  }, [type, sorting, lenses]);

  useEffect(() => {
    let newLenses = lenses;

    if (currentTab === 1) {
      // favorites
      newLenses = newLenses.filter((lens) => lens.favorited);
    } else if (currentTab === 2) {
      // personal
      newLenses = newLenses.filter((lens) => lens.type === "personal");
    } else if (currentTab === 3) {
      // community
      newLenses = newLenses.filter((lens) => lens.type === "community");
    } else if (currentTab === 4) {
      // draft
      newLenses = newLenses.filter((lens) => lens.status === "draft");
    }
    setFilteredLenses(newLenses);
  }, [currentTab, lenses]);

  return (
    <AppLayout contentWithTitle>
      <section className="lenses">
        <h1>Lens Library</h1>
        {/* TABS BAR */}
        <Box sx={{ bgcolor: "background.paper" }} className="lenses-container">
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons={false}
            aria-label="scrollable prevent tabs"
          >
            <Tab label="All" />
            <Tab label="Favorites" />
            <Tab label="Personal" />
            <Tab disabled={true} label="Community (Coming Soon)" />
          </Tabs>
        </Box>
        {/* FILTERS BAR */}
        <Box
          sx={{ bgcolor: "background.paper", padding: "24px 16px" }}
          className="lenses-filters"
        >
          <div>
            <Search className="search-wrapper">
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search Coming Soon"
                inputProps={{ "aria-label": "search" }}
                disabled={true}
              />
            </Search>
            <Typography>Filter by</Typography>
            <FormControl fullWidth>
              <InputLabel id="type-select-label">Lens Type</InputLabel>
              <Select
                labelId="type-select-label"
                id="type-select"
                value={type}
                label="Lens Type"
                onChange={handleTypeChange}
              >
                {types.map((type_option) => (
                  <MenuItem key={type_option} value={type_option}>
                    {type_option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="sorting-select-label">Sorting</InputLabel>
              <Select
                labelId="sorting-select-label"
                id="sorting-select"
                value={sorting}
                label="Sorting"
                onChange={handleSortingChange}
              >
                {sortings["All types"].map((sorting_option) => (
                  <MenuItem key={sorting_option} value={sorting_option}>
                    {sorting_option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography>View</Typography>
            <div className="view-actions">
              <Button
                className={`${view == "grid" ? "active-view" : ""}`}
                onClick={() => handleViewChange("grid")}
              >
                <GridOnIcon />
              </Button>
              <Button
                className={`${view == "list" ? "active-view" : ""}`}
                onClick={() => handleViewChange("list")}
              >
                <ListIcon />
              </Button>
            </div>
          </div>
        </Box>
        <Divider />
        {/* LENSES LISTING */}
        {/*
        <Box
          sx={{ bgcolor: "background.paper", padding: "32px" }}
          className="lenses-listing"
        >
          {isLoading ? (
            <CircularProgress />
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <>
              <Typography variant="h6" component="h6">
                Best matches
              </Typography>
              <div className={`lenses-grid ${view}`}>
                {bestMatches.map((lensData) => (
                  <LensCard
                    key={lensData.lensId}
                    info={lensData}
                    addToLibrary={handleOpenDialog}
                  />
                ))}
              </div>
            </>
          )}
        </Box>
        */}
        <Box
          sx={{ bgcolor: "background.paper", padding: "32px" }}
          className="lenses-listing"
        >
          {isAuth0Loading ? (
            <CircularProgress />
          ) : !isAuthenticated ? (
            <Typography color="error">Please log in to view your lenses.</Typography>
          ) : isLoading ? (
            <CircularProgress />
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <>
              <Typography variant="h6" component="h6">
                Latest
              </Typography>
              <div className={`lenses-grid ${view}`}>
                {filteredLenses.map((lensData) => (
                  <LensCard key={lensData.lensId} info={lensData} />
                ))}
              </div>
            </>
          )}
        </Box>
        <Box
          sx={{ bgcolor: "background.paper", padding: "32px" }}
          className="results-row pagination"
        >
          <span>Rows per page:</span>
          <FormControl sx={{ m: 1, minWidth: 90 }} size="small">
            <Select
              id="demo-simple-select"
              value={rowsPerPage}
              label=""
              onChange={handleChange}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
          <span>1-10 of 25</span>
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="pagination previous page"
          >
            <ChevronLeftOutlinedIcon />
          </IconButton>
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="pagination next page"
          >
            <ChevronRightOutlinedIcon />
          </IconButton>
        </Box>
      </section>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"The lens has been added to your library and is ready to use."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormControlLabel
              control={<Checkbox />}
              label="Don't show this message again."
              sx={{ marginRight: 0 }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </AppLayout>
  );
};

export default LensLibrary;
