import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { Button, FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

const dummyBillings = [
    {
        title: 'How to host a community convention',
        excerpt: 'Libero luctus pharetra porttitor mauris. Id purus placerat risus sem vestibulum phasellus mus sollicitudin. Cursus mattis ullamcorper sodales arcu vulputate. Bibendum proin elementum id consectetur ultrices scelerisque aenean cras. Leo vitae suscipit dui aliquet...'
    },
    {
        title: 'What is a community convention',
        excerpt: 'Libero luctus pharetra porttitor mauris. Id purus placerat risus sem vestibulum phasellus mus sollicitudin. Cursus mattis ullamcorper sodales arcu vulputate. Bibendum proin elementum id consectetur ultrices scelerisque aenean cras. Leo vitae suscipit dui aliquet...'
    },
    {
        title: 'Community convention legal requirements',
        excerpt: 'Libero luctus pharetra porttitor mauris. Id purus placerat risus sem vestibulum phasellus mus sollicitudin. Cursus mattis ullamcorper sodales arcu vulputate. Bibendum proin elementum id consectetur ultrices scelerisque aenean cras. Leo vitae suscipit dui aliquet...'
    },
    {
        title: 'Effective Convention for a Community',
        excerpt: 'Libero luctus pharetra porttitor mauris. Id purus placerat risus sem vestibulum phasellus mus sollicitudin. Cursus mattis ullamcorper sodales arcu vulputate. Bibendum proin elementum id consectetur ultrices scelerisque aenean cras. Leo vitae suscipit dui aliquet...'
    },
]
const PlanAndBillings = () => {
    const [plan, setPlan] = React.useState('Do-GOODER');
    const [billings, setBillings] = React.useState(dummyBillings);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChange = (event) => {
        setRowsPerPage(event.target.value);
    };
    const handlePlanChange = (event) => {
        setPlan(event.target.value);
    }
   return (
        <div className='basic-profile' >
            <h2>Current Plan</h2>
            <div className="profile-fields">
                <FormControl variant="filled">
                    <InputLabel id="plan-label">Plan</InputLabel>
                    <Select
                        labelId="plan-label"
                        id="plan"
                        value={plan}
                        onChange={handlePlanChange}
                    >
                        <MenuItem value="">
                            <em>Choose one...</em>
                        </MenuItem>
                        <MenuItem value="Do-GOODER">Do-GOODER</MenuItem>
                        <MenuItem value="Changemaker">Changemaker</MenuItem>
                        <MenuItem value="Enterprise">Enterprise</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className="buttons">
                <Button>Cancel</Button>
                <Button variant="contained">Update Plan</Button>
            </div>
            <h2>Billings</h2>
            <div className="billings">
                {billings.map((result, i) => (
                    <div className="results-row" key={`result-${i}`}>
                        <h3>
                            {result.title}
                        </h3>
                        <p>
                            {result.excerpt}
                        </p>
                    </div>
                ))}

                <div className="results-row pagination">
                    <span>
                        Rows per page:
                    </span>
                    <FormControl sx={{ m: 1, minWidth: 90 }} size="small">
                        <Select
                            id="demo-simple-select"
                            value={rowsPerPage}
                            label=""
                            onChange={handleChange}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>
                    </FormControl>
                    <span>
                        1-10 of 25
                    </span>
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="pagination previous page">
                        <ChevronLeftOutlinedIcon />
                    </IconButton>
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="pagination next page">
                        <ChevronRightOutlinedIcon />
                    </IconButton>
                </div>
            </div>

        </div>
    );
};

export default PlanAndBillings;
