import { Button, TextField } from '@mui/material';
import React from 'react';

const Password = () => {

    return (
        <div className='basic-profile' >
            <h2>Change Password</h2>
            <div className="profile-fields">
                <TextField variant="filled" label="Curent Password" type="password"></TextField>
                <TextField variant="filled" label="New Password" type="password"></TextField>
                <TextField variant="filled" label="Confirm Password" type="password"></TextField>
            </div>
            <div className="buttons">
                <Button>Cancel</Button>
                <Button variant="contained">Update</Button>
            </div>
        </div>
    );
};

export default Password;
