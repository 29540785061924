import React from "react";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  TextField,
  Button,
  Typography,
} from "@mui/material";

const ContextStoriesLens = ({
  lensName,
  setLensName,
  perspective,
  setPerspective,
  contextStory1,
  setContextStory1,
  contextStory2,
  setContextStory2,
  contextStory3,
  setContextStory3,
  activeStep,
  nextStep,
  prevStep,
  onFinish,
  isEditing = false,
  title,
  setTitle,
  description,
  setDescription,
  authors,
  setAuthors,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
        <p>
          Create a Context Stories Lens to generate an answer that considers
          context and other intangible factors that are more qualitative in
          nature. These elements can help generate responses that consider
          intangible elements, feelings, and perspectives that may not otherwise
          come across in a prompt.
        </p>
        <Typography className="footer-message">
          <p>Please note:</p>
          <p>
            Efficacy of Lenses is dependent on the information used to create
            the lens as well as the subsequent content and questions it is used
            in conjunction with. Lenses are not guaranteed to change AI PRIORI's
            responses.
          </p>
        </Typography>
        <div className="context-stories">
          <FormControl className="top-labeled" style={{ marginTop: "20px" }}>
            <InputLabel shrink htmlFor="lens-title-input">
              Lens Title
            </InputLabel>
            <TextField
              required
              placeholder="Enter a title for your lens"
              id="lens-title-input"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormControl>
          <FormControl className="top-labeled" style={{ marginTop: "20px" }}>
            <InputLabel shrink htmlFor="lens-description-input">
              Lens Description
            </InputLabel>
            <TextField
              multiline
              required
              rows={2}
              placeholder="Provide a brief description of your lens"
              id="lens-description-input"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormControl>
          <FormControl className="top-labeled" style={{ marginTop: "20px" }}>
            <InputLabel shrink htmlFor="lens-authors-input">
              Authors
            </InputLabel>
            <TextField
              required
              placeholder="Enter author names (comma-separated)"
              id="lens-authors-input"
              value={authors}
              onChange={(e) => setAuthors(e.target.value)}
            />
          </FormControl>
          <FormControl className="top-labeled" style={{ marginTop: "20px" }}>
            <InputLabel shrink htmlFor="lens-name-input">
              Lens Name:
              {/*<span className="required">*</span>*/}
            </InputLabel>
            <TextField
              required
              placeholder="Write here"
              id="lens-name-input"
              value={lensName}
              onChange={(e) => setLensName(e.target.value)}
            />
          </FormControl>
          <FormControl className="top-labeled" style={{ marginTop: "20px" }}>
            <InputLabel shrink htmlFor="perspective-input">
              Perspective:
              {/*<span className="required">*</span>*/}
            </InputLabel>
            <Typography variant="body2" gutterBottom>
              Enter the perspective (e.g., Community Member, Nonprofit Staff,
              Client (recipient of services), Volunteer, Researcher, Funder,
              Partner, Policymaker, Child, Student, Parent, etc.):
            </Typography>
            <TextField
              required
              placeholder="Write here"
              id="perspective-input"
              value={perspective}
              onChange={(e) => setPerspective(e.target.value)}
            />
          </FormControl>
          <FormControl className="top-labeled" style={{ marginTop: "20px" }}>
            <InputLabel shrink htmlFor="context-story-1-input">
              Context Story 1:
              {/*<span className="required">*</span>*/}
            </InputLabel>
            <Typography variant="body2" gutterBottom>
              Provide a contextual story related to the selected perspective:
            </Typography>
            <TextField
              multiline
              required
              rows={4}
              placeholder="Write here"
              id="context-story-1-input"
              value={contextStory1}
              onChange={(e) => setContextStory1(e.target.value)}
            />
          </FormControl>
          <FormControl className="top-labeled" style={{ marginTop: "20px" }}>
            <InputLabel shrink htmlFor="context-story-2-input">
              Context Story 2:
              {/*<span className="required">*</span>*/}
            </InputLabel>
            <Typography variant="body2" gutterBottom>
              Provide another contextual story related to the selected
              perspective:
            </Typography>
            <TextField
              multiline
              required
              rows={4}
              placeholder="Write here"
              id="context-story-2-input"
              value={contextStory2}
              onChange={(e) => setContextStory2(e.target.value)}
            />
          </FormControl>
          <FormControl className="top-labeled" style={{ marginTop: "20px" }}>
            <InputLabel shrink htmlFor="context-story-3-input">
              Context Story 3:
              {/*<span className="required">*</span>*/}
            </InputLabel>
            <Typography variant="body2" gutterBottom>
              Provide a final contextual story related to the selected
              perspective:
            </Typography>
            <TextField
              multiline
              required
              rows={4}
              placeholder="Write here"
              id="context-story-3-input"
              value={contextStory3}
              onChange={(e) => setContextStory3(e.target.value)}
            />
          </FormControl>
        </div>
        <div className="actions" style={{ marginTop: "20px" }}>
          {isEditing ? (
            <>
              <Button onClick={() => navigate("/lenses")}>Cancel</Button>
              <Button variant="contained" onClick={onFinish}>
                Update lens
              </Button>
            </>
          ) : (
            <>
              <Button onClick={prevStep}>Back</Button>
              <Button variant="contained" onClick={onFinish}>
                Create lens
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ContextStoriesLens;
