import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import { FreeMode, Mousewheel, Navigation, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export default function DocumentsSlider({ documents, onShowFileSummary }) {
  const handleFileClicked = (title) => {
    if (onShowFileSummary) onShowFileSummary(title);
  };
  return (
    <div className="all-documents">
      {documents.length === 0 ? (
        <div className="empty-documents">
          <div className="disabled-arrow">
            <ArrowBackOutlinedIcon />
          </div>
          <div className="message">No documents yet.</div>
          <div className="disabled-arrow">
            <ArrowForwardOutlinedIcon />
          </div>
        </div>
      ) : (
        <>
          <div className="swiper-container">
            <IconButton className="arrow-left">
              <ArrowBackOutlinedIcon />
            </IconButton>
            <Swiper
              slidesPerView={4}
              spaceBetween={24}
              navigation={{ nextEl: ".arrow-right", prevEl: ".arrow-left" }}
              modules={[Navigation]}
              className="desktop-swiper"
              breakpoints={{
                900: {
                  slidesPerView: 2,
                },
                1100: {
                  slidesPerView: 3,
                },
                1400: {
                  slidesPerView: 4,
                },
              }}
            >
              {documents.map((doc, i) => (
                <SwiperSlide key={`slide-document-${i}`}>
                  <div
                    className="file-card"
                    onClick={() => handleFileClicked(doc)}
                  >
                    <div className="file-content">
                      <h4>
                        {doc.type === "pdf" ? (
                          <PictureAsPdfOutlinedIcon />
                        ) : (
                          <InsertDriveFileIcon />
                        )}
                        <Typography
                          noWrap
                          sx={{
                            wordBreak: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: "1",
                            WebkitBoxOrient: "vertical",
                            width: "130px",
                          }}
                        >
                          {doc.name}
                        </Typography>
                      </h4>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <IconButton className="arrow-right">
              <ArrowForwardOutlinedIcon />
            </IconButton>
          </div>
          <Swiper
            direction={"horizontal"}
            slidesPerView={"auto"}
            freeMode={true}
            mousewheel={true}
            spaceBetween={"8px"}
            modules={[FreeMode, Scrollbar, Mousewheel]}
            className="mobile-swiper"
          >
            {documents.map((doc, i) => (
              <SwiperSlide key={`slide-document-${i}`}>
                <div
                  className="file-card"
                  onClick={() => handleFileClicked(doc)}
                >
                  <div className="file-content">
                    <h4>
                      {doc.type === "pdf" ? (
                        <PictureAsPdfOutlinedIcon />
                      ) : (
                        <InsertDriveFileIcon />
                      )}
                      <Typography
                        noWrap
                        sx={{
                          wordBreak: "break-word",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          WebkitLineClamp: "1",
                          WebkitBoxOrient: "vertical",
                          width: "130px",
                        }}
                      >
                        {doc.name}
                      </Typography>
                    </h4>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )}
    </div>
  );
}
