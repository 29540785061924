import { useAuth0 } from "@auth0/auth0-react";
import React from 'react';
import '../../styles/components/hero.scss';

const WelcomeHero = ({
    title,
    paragraph
}) => {
    const { isAuthenticated, error, user } = useAuth0();
    return (
        <section className='hero is-fullheight welcome'>
            <span className="ornament-triangle-1"></span>
            <span className="ornament-triangle-2"></span>
            <span className="ornament-circle-1"></span>

            <div className='hero-body'>
                <h1>
                    {title && title}
                </h1>
                {paragraph && paragraph}
            </div>
        </section>
    );
};

export default WelcomeHero;
