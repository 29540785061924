import FileUtils from "./FileUtils";
import ApiUtils from "../utils/ApiUtils";
import {
  Button,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { styled } from "@mui/material/styles";
import LensGenerator from "../Lenses/LensGenerator";

/**
 * Converts a byte value to a human readable string with units.
 *
 * @param {number} bytes - Number of bytes
 * @returns {string} Human readable string with units
 */
export function convertBytes(bytes) {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;
  const gigabyte = megabyte * 1024;

  let result, unit;

  if (bytes < kilobyte) {
    result = bytes;
    unit = "B";
  } else if (bytes < megabyte) {
    result = bytes / kilobyte;
    unit = "KB";
  } else if (bytes < gigabyte) {
    result = bytes / megabyte;
    unit = "MB";
  } else {
    result = bytes / gigabyte;
    unit = "GB";
  }

  return result.toFixed(1) + " " + unit;
}

/**
 * Handles file upload and processing logic
 *
 * @param {React.ChangeEvent<HTMLInputElement>} event
 * @param {Object} params
 * @param {File[]} params.files
 * @param {Function} params.setFiles
 * @param {string} [params.sessionId]
 * @param {Function} params.setSessionId
 * @param {Function} params.setLoading
 * @param {Object} params.filesInfo
 * @param {Function} params.setFilesInfo
 * @param {Object[]} params.documents
 * @param {Function} params.setDocuments
 * @param {boolean} params.isNewSession
 * @param {Function} params.setIsNewSession
 * @param {boolean} params.uploadFilesFinished
 * @param {Function} params.setUploadFilesFinished
 * @param {Object} params.user
 * @param {Function} params.getAccessTokenSilently
 * @param {Function} params.alert
 * @param {Function} params.sendDocumentSummaryRequest
 * @returns {Promise<void>}
 */
export const processFile = async (
  e,
  {
    setFiles,
    sessionId,
    setSessionId,
    setLoading,
    setFilesInfo,
    setDocuments,
    setIsNewSession,
    setUploadFilesFinished,
    files,
    user,
    getAccessTokenSilently,
    alert,
    sendDocumentSummaryRequest,
  }
) => {
  const accessToken = await getAccessTokenSilently();
  // Check usage before sending document summary request
  const usageResponse = await ApiUtils.incrementUsage(
    user.sub,
    accessToken,
    true,
    false
  );
  if (
    usageResponse.data === "Daily file limit reached" ||
    usageResponse.data === "Both file and query limits reached for the day"
  ) {
    alert(usageResponse.data);
    return;
  }
  setLoading(true);

  setFilesInfo((prev) => {
    const newFilesInfo = {
      ...prev,
      uploadingFiles: true,
      totalFiles: e.target.files.length,
      uploadedFiles: 1,
    };
    return newFilesInfo;
  });

  let currentSessionId = sessionId;
  let filesUploaded = 1;

  try {
    //validate uploaded files
    const selectedFiles = FileUtils.validateAndGetFiles(e, files, setFiles); // This now returns an array of files.

    // If there's no session, initialize it with the first file.
    if (!currentSessionId && selectedFiles.length > 0) {
      const firstFile = selectedFiles[0];
      const responseData = await ApiUtils.initiateNewSession(
        user,
        firstFile.name,
        accessToken
      );
      setSessionId(responseData.sessionId);
      currentSessionId = responseData.sessionId;
      const dataForFirstFile = await FileUtils.readFileAsDataURL(firstFile);
      const { URL } = await ApiUtils.getUploadUrlForFile(
        firstFile,
        accessToken,
        currentSessionId
      );
      await ApiUtils.uploadFileToURL(URL, dataForFirstFile);

      const summary_message = await sendDocumentSummaryRequest(
        currentSessionId,
        firstFile,
        "True"
      );

      setDocuments((documents) => [
        ...documents,
        {
          name: firstFile.name,
          size: convertBytes(firstFile.size),
          type: "pdf",
          summary_message: summary_message,
        },
      ]);

      setIsNewSession(false);
      setFilesInfo((prev) => {
        const newFilesInfo = {
          ...prev,
          uploadedFiles: filesUploaded,
        };
        return newFilesInfo;
      });
      filesUploaded++;
    }

    // Process the remaining files.
    for (
      let i = currentSessionId === sessionId ? 0 : 1;
      i < selectedFiles.length;
      i++
    ) {
      const file = selectedFiles[i];
      const data = await FileUtils.readFileAsDataURL(file);
      const { URL } = await ApiUtils.getUploadUrlForFile(
        file,
        accessToken,
        currentSessionId
      );
      await ApiUtils.uploadFileToURL(URL, data);
      const summary_message = await sendDocumentSummaryRequest(
        currentSessionId,
        file,
        "False"
      );

      setDocuments((documents) => [
        ...documents,
        {
          name: file.name,
          size: convertBytes(file.size),
          type: "pdf",
          summary_message: summary_message,
        },
      ]);

      setFilesInfo((prev) => {
        const newFilesInfo = {
          ...prev,
          uploadedFiles: filesUploaded,
        };
        return newFilesInfo;
      });
      filesUploaded++;
    }

    e.target.value = null; // Clear the file input
    setIsNewSession(false);
    setUploadFilesFinished(true);
  } catch (error) {
    alert(error.message);
  } finally {
    setLoading(false);
  }
  setFilesInfo((prev) => {
    const newFilesInfo = {
      ...prev,
      uploadingFiles: false,
    };
    return newFilesInfo;
  });
};

/**
 * Styled expand more icon that rotates on expand/collapse.
 *
 * @param {object} props - Component props
 * @param {boolean} props.expand - Whether expanded or not
 * @param {object} props.theme - MUI theme
 * @returns {JSX.Element}
 */
export const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <ExpandMoreIcon {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

/**
 * Renders upload document message.
 *
 * @param {object} props - Component props
 * @param {Function} props.handleFileChange - File change handler
 * @param {boolean} props.loading - Whether upload is in progress
 * @returns {JSX.Element}
 */
export function UploadDocumentMessage({ handleFileChange, loading }) {
  return (
    <div className="empty-chat-messages">
      <div className="message-container">
        <h4>To start, upload your document(s) or ask your question(s).</h4>
        <p>
          Start uploading your documents by either dragging and dropping them
          into this window or clicking the button below. You can upload a
          maximum of 5 documents of 10MB each, in the following formats: .pdf,
          .doc, .csv, etc.,
        </p>
        <Button
          startIcon={<FileUploadIcon />}
          variant="contained"
          component="label"
          disabled={loading}
        >
          select documents
          <input
            type="file"
            id="pdfFile"
            name="pdfFile"
            accept=".pdf"
            onChange={handleFileChange}
            multiple
          />
        </Button>
      </div>
    </div>
  );
}

/**
 * Renders uploading files progress message.
 *
 * @param {object} props - Component props
 * @param {Function} props.onCancel - Cancel upload handler
 * @param {boolean} props.loading - Whether upload is in progress
 * @param {number} props.totalFiles - Total files to upload
 * @param {number} props.completedFiles - Files uploaded so far
 * @returns {JSX.Element}
 */
export function UploadingFilesMessage({
  onCancel,
  loading,
  totalFiles,
  completedFiles,
}) {
  return (
    <div className="empty-chat-messages message-w-spinner">
      <div className="message-container">
        <CircularProgress
          sx={{ width: "44px", display: "block", marginBottom: 2 }}
          size="small"
        />
        <h4>
          <span>In progress / </span>
          {completedFiles} of {totalFiles} documents
        </h4>
        {/** Remove cancel button until cancel logic can be implemented 
        <Button
          startIcon={<CancelIcon />}
          variant="contained"
          onClick={onCancel}
        >
          Cancel Upload
        </Button>
        */}
      </div>
    </div>
  );
}

export function generateLensArg(selectedLenses, lenses, chatHistory) {
  let generatedLens = "";
  if (selectedLenses && selectedLenses.length > 0) {
    const selectedDefinitionLenses = lenses
      .filter(
        (lens) =>
          lens.lens_type === "Definition" &&
          selectedLenses.includes(lens.lensId)
      )
      .map((lens) => lens);

    const selectedOrgGoalsStrategiesLenses = lenses
      .filter(
        (lens) =>
          lens.lens_type === "Organization Goals and Strategies" &&
          selectedLenses.includes(lens.lensId)
      )
      .map((lens) => lens);

    const selectedPriorityAudienceLenses = lenses
      .filter(
        (lens) =>
          lens.lens_type === "Priority Audience" &&
          selectedLenses.includes(lens.lensId)
      )
      .map((lens) => lens);
    const selectedPersonaLenses = lenses
      .filter(
        (lens) =>
          lens.lens_type === "Persona" &&
          selectedLenses.includes(lens.lensId)
      )
      .map((lens) => lens);
    const selectedTaskOrientationLenses = lenses
      .filter(
        (lens) =>
          lens.lens_type === "Task Orientation" &&
          selectedLenses.includes(lens.lensId)
      )
      .map((lens) => lens);
    const selectedContextStoriesLenses = lenses
      .filter(
        (lens) =>
          lens.lens_type === "Context Stories" &&
          selectedLenses.includes(lens.lensId)
      )
      .map((lens) => lens);
    generatedLens = LensGenerator({
      lenses: [
        ...selectedDefinitionLenses,
        ...selectedOrgGoalsStrategiesLenses,
        ...selectedPriorityAudienceLenses,
        ...selectedPersonaLenses,
        ...selectedTaskOrientationLenses,
        ...selectedContextStoriesLenses,
      ],
      chatHistory: chatHistory
    });
  } else {
    // If no lenses are selected, pass an empty array for lenses
    generatedLens = LensGenerator({
      lenses: [],
      chatHistory
    });
  }
  return generatedLens ? generatedLens.prompt : "";
}
