import SearchPage from '../components/Search/SearchPage';
import AppLayout from "../layouts/AppLayout";


const Search = () => {
    return (
        <AppLayout contentWithTitle>
            <SearchPage />
        </AppLayout>
    )
}

export default Search;