export class SessionUtils {
  static formatSessionListData(data) {
    const groupedByMonth = data.reduce((acc, session) => {
      const date = new Date(session.sessionDate);
      const monthYear = date.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }
      acc[monthYear].push({
        ...session,
        name: session.sessionName.slice(0, 90),
        favorited: session.hasOwnProperty("favorited")
          ? session.favorited
          : false,
      });
      return acc;
    }, {});

    // Sort the sessions within each group based on sessionDate in descending order
    Object.values(groupedByMonth).forEach((group) => {
      group.sort((a, b) => new Date(b.sessionDate) - new Date(a.sessionDate));
    });

    // Sort the groups based on their labels (monthYear) in descending order
    const sortedGroups = Object.entries(groupedByMonth).sort((a, b) => {
      const dateA = new Date(a[0]);
      const dateB = new Date(b[0]);
      return dateB - dateA;
    });

    // Convert the sorted groups into the desired array structure
    return sortedGroups.map(([groupLabel, items]) => ({
      groupLabel,
      items,
    }));
  }

  static paginateItems(
    chatHistory,
    startIndex,
    endIndex,
    showOnlyFavorites = false
  ) {
    let itemIndex = 0; // Keeps track of the current index across all items

    const itemsToDisplay = chatHistory.reduce((acc, group) => {
      // Filter based on the favorited status if showOnlyFavorites is true
      const relevantItems = showOnlyFavorites
        ? group.items.filter((item) => item.favorited)
        : group.items;

      // Then filter based on the pagination slice
      const filteredItems = relevantItems.filter(() => {
        const display = itemIndex >= startIndex && itemIndex < endIndex;
        itemIndex++; // Increment for each item across all groups, regardless of whether it's displayed
        return display;
      });

      // Only add the group if it has any items to display
      if (filteredItems.length > 0) {
        acc.push({ ...group, items: filteredItems });
      }
      return acc;
    }, []);

    return itemsToDisplay;
  }
}
