import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ApiUtils from "../utils/ApiUtils";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import SearchIcon from "@mui/icons-material/Search";
import StarIcon from "@mui/icons-material/Star";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import { Box, CircularProgress } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import "../../styles/components/chat-history.scss";
import { SessionUtils } from "../utils/SessionUtils";
import { useNavigate } from "react-router-dom";
import { useChatContext } from "../Contexts/ChatContext";

const ChatHistory = () => {
  const [chatHistory, setChatHistory] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [showOnlyFavorites, setShowOnlyFavorites] = useState(false);
  const { user, getAccessTokenSilently } = useAuth0();
  const { loadChatSession } = useChatContext();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchChatHistoryList = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await ApiUtils.fetchChatHistoryList(user, accessToken);
        const formattedData = SessionUtils.formatSessionListData(
          response.sessions
        );
        setChatHistory(formattedData);
      } catch (error) {
        console.error("Error fetching chat history:", error);
      }
    };
    fetchChatHistoryList();
  }, [getAccessTokenSilently, user]);

  const totalItems = React.useMemo(() => {
    if (!showOnlyFavorites) {
      // If not filtering by favorites, count all items
      return chatHistory.reduce(
        (total, group) => total + group.items.length,
        0
      );
    } else {
      // If filtering by favorites, only count favorited items
      return chatHistory.reduce(
        (total, group) =>
          total + group.items.filter((item) => item.favorited).length,
        0
      );
    }
  }, [chatHistory, showOnlyFavorites]);
  const totalPages = Math.ceil(totalItems / rowsPerPage);
  const startIndex = currentPage * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const startNumber = currentPage * rowsPerPage + 1;
  const endNumber = Math.min(startNumber + rowsPerPage - 1, totalItems);
  const itemsToDisplay = SessionUtils.paginateItems(
    chatHistory,
    startIndex,
    endIndex,
    showOnlyFavorites
  );

  const handleChange = (event) => {
    const value = event.target.value;
    setRowsPerPage(parseInt(value, 10)); // Ensure it's an integer
    setCurrentPage(0);
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
  };
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };
  const handleItemClick = async (item) => {
    try {
      const accessToken = await getAccessTokenSilently();
      await loadChatSession(item.sessionId, accessToken);
      navigate("/chat");
    } catch (error) {
      console.error("Error loading chat session:", error);
    }
  };

  return (
    <section className={`chat-history ${isLoading ? "is-loading" : ""}`}>
      {isLoading ? (
        <div className="loading-state">
          <CircularProgress
            sx={{ width: "44px", marginBottom: 2 }}
            size="small"
          />
          Loading your chat history...
        </div>
      ) : (
        <>
          <span className="ornament-triangle-1"></span>
          <span className="ornament-triangle-2"></span>
          <span className="ornament-circle-1"></span>
          <span className="ornament-circle-2"></span>

          <div className="history-container">
            <h2>
              <span>Your</span> Chat History
            </h2>
            {/* TODO: Implement Search
            <Paper
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
              }}
            >
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search google maps" }}
              />
            </Paper> 
            */}
            {/** Comment out until favorite is available
            <FormControlLabel
              checked={showOnlyFavorites}
              control={<Checkbox name="only_favorites" />}
              label="Show only favorites"
              onChange={(e) => {
                setShowOnlyFavorites(e.target.checked);
                setCurrentPage(0);
              }}
              name="only_favorites"
            />
            */}
            {itemsToDisplay.map((group, index) => (
              <Box className="history-group" key={`group-${index}`}>
                <h4>{group.groupLabel}</h4>
                <div className="history-items">
                  {group.items.map((item, sub_index) => (
                    <div
                      className={`history-item ${
                        item.favorited ? "starred" : ""
                      }`}
                      key={`item-${sub_index}`}
                      onClick={() => handleItemClick(item)}
                    >
                      <span>{item.name}</span>
                      {/** Comment out until favorite feature is available
                      <IconButton
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="favorited"
                      >
                        {!item.favorited ? (
                          <StarBorderOutlinedIcon />
                        ) : (
                          <StarIcon />
                        )}
                      </IconButton>
                      */}
                    </div>
                  ))}
                </div>
              </Box>
            ))}
            <div className="pagination">
              <span>Rows per page:</span>
              <FormControl sx={{ m: 1, minWidth: 90 }} size="small">
                <Select
                  id="rowsPerPAge"
                  value={rowsPerPage}
                  label=""
                  onChange={handleChange}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              <span>{`${startNumber}-${endNumber} of ${totalItems}`}</span>
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="previous page"
                onClick={handlePreviousPage}
                disabled={currentPage === 0}
              >
                <ChevronLeftOutlinedIcon />
              </IconButton>
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="next page"
                onClick={handleNextPage}
                disabled={currentPage >= totalPages - 1}
              >
                <ChevronRightOutlinedIcon />
              </IconButton>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default ChatHistory;
