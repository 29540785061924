import Faqs from '../components/Faqs/Faqs';
import AppLayout from '../layouts/AppLayout';
import '../styles/welcome.css';
import faqsData from '../data/faqs.json'

const FAQs = () => {
    return (
        <AppLayout contentWithTitle>
            <Faqs title="FAQs" content={faqsData} />
        </AppLayout>
    )
}

export default FAQs;