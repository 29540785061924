import "../../styles/welcome.css";

import { useAuth0 } from "@auth0/auth0-react";
import ChatHistory from "./ChatHistory";
import HowToUse from "./HowToUse";
import UploadPDF from "./UploadPDF";
import WelcomeHero from "../utils/WelcomeHero";

const Welcome = () => {
  const { isAuthenticated, error, user } = useAuth0();
  return (
    <div className="main">
      <WelcomeHero
        title={
          !error && isAuthenticated && user ? (
            <>
              Welcome back <b>{user.nickname}</b>
            </>
          ) : (
            <>
              Welcome to <b>AI PRIORI</b>
            </>
          )
        }
        paragraph={
          <p>
            Turn your information into insights. Review multiple documents
            simultaneously and customize how <b>AI PRIORI®</b> reviews
            them–ensuring you get the most relevant response every time.
          </p>
        }
      />
      <UploadPDF />
      {!error && isAuthenticated && user ? (
        <>
          <ChatHistory />
          <HowToUse />
        </>
      ) : (
        <HowToUse />
      )}
    </div>
  );
};

export default Welcome;
