import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/footer.scss";

//Paths
const logoPath = "/img/AI_Priori_white-logo.svg";

const Footer = () => {
  // use our custom hook to get the the window size
  const navigate = useNavigate();
  return (
    <footer className="Homepage-Footer">
      <div className="footer-circle-grey"></div>
      <div className="footer-circle-red"></div>

      <div className="footer-elements">
        <div className="footer-body">
          <div className="footer-logo">
            <img
              smooth="true"
              className="Homepage-Footer-Logo"
              src={logoPath}
              alt=""
              onClick={() => navigate("/")}
            ></img>
          </div>
          <div className="footer-cta">
            <div className="right-footer-menu">
              <h4 className="footer-menu">Get In Touch</h4>
            </div>
            <ul className="right-footer-menu no-bullets">
              <li>
                <img
                  src="/img/twitter.png"
                  className="twitter-padding footer-social"
                  smooth="true"
                  alt=""
                  onClick={() => {
                    window.location.href = "https://twitter.com/AIPRIORI_INC";
                  }}
                ></img>
              </li>
              <li>
                <img
                  src="/img/linkedin.jpg"
                  className="footer-social"
                  smooth="true"
                  alt=""
                  onClick={() => {
                    window.location.href =
                      "https://www.linkedin.com/company/ai-priori-inc";
                  }}
                ></img>
              </li>
              <li>
                <img
                  src="/img/email.png"
                  className="mail-padding footer-social"
                  smooth="true"
                  alt=""
                  onClick={() => {
                    window.open("https://aipriori.com/contactus", "_blank");
                  }} 
                ></img>
              </li>
            </ul>
          </div>
        </div>
        <div className="sub-footer">
          <div className="sub-footer-links">
            <ul className="sub-footer-links">
              <li>
                <a href="https://aipriori.com/privacypolicy" className={"nav-small-link"}>
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="https://aipriori.com/terms" className={"nav-small-link"}>
                  Terms of Service
                </a>
              </li>
              <li>
                <a href="https://aipriori.com/contactus" className={"nav-small-link"}>
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-copy-text">
            ©2024 AI PRIORI, INC. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
