import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Box, IconButton, Typography, Snackbar } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import React, { useState } from "react";
import "../../styles/CopyIcon.css";

const SummaryMessage = ({ sender, message, closeButton, onClose }) => {
  const [displayNotification, setDisplayNotification] = useState(false);

  const handleClose = () => {
    setDisplayNotification(false);
    if (onClose) onClose();
  };
  const handleCopy = () => {
    navigator.clipboard.writeText(message);
    setDisplayNotification(true);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <Box className={`ai-card summary ${closeButton ? "close-btn" : ""}`}>
      <Box className="heading">
        <h3>
          <Typography
            sx={{
              wordBreak: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
              whiteSpace: "nowrap",
            }}
          >
            {sender}
          </Typography>
        </h3>
        <div className="actions">
          {!closeButton && (
            <>
              <div className="actions-left">
                <IconButton onClick={handleCopy}>
                  <ContentCopyIcon sx={{ color: "#47246B" }} />
                </IconButton>
                {/** 
                <IconButton>
                  <RefreshIcon sx={{ color: "#47246B" }} />
                </IconButton>
          */}
              </div>
              <div className="actions-right">
                <IconButton>
                  <ThumbUpIcon sx={{ color: "#47246B" }} />
                </IconButton>
                <IconButton>
                  <ThumbDownIcon sx={{ color: "#47246B" }} />
                </IconButton>
              </div>
              <Snackbar
                open={displayNotification}
                autoHideDuration={5000}
                onClose={handleClose}
                message="Text copied to the clipboard"
                action={action}
              />
            </>
          )}
          {closeButton && (
            <IconButton onClick={handleClose}>
              <CloseOutlinedIcon sx={{ color: "#47246B" }} />
            </IconButton>
          )}
        </div>
      </Box>
      <Box className="card-section-content">
        {typeof message === "string"
          ? message.split("<br>").map((line, lineIndex) => (
              <span key={lineIndex}>
                {line}
                {lineIndex < message.split("<br>").length - 1 && <br />}
              </span>
            ))
          : message}
      </Box>
    </Box>
  );
};

export default SummaryMessage;
