const RemixIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 4.5H4.5V8.25H3V3H13.5V0.75L16.5 3.75L13.5 6.75V4.5ZM4.5 13.5H13.5V9.75H15V15H4.5V17.25L1.5 14.25L4.5 11.25V13.5ZM8.25 6H9.75V8.25L12 8.25V9.75H9.75V12H8.25V9.75H6V8.25H8.25V6Z" fill="currentColor" />
        </svg>

    );
};

export default RemixIcon;
