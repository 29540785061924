import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, List, ListItemButton, ListItemIcon, ListItemText, Paper } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import '../../styles/Search.scss';

const dummyResults = [
    {
        title: 'How to host a community convention',
        excerpt: 'Libero luctus pharetra porttitor mauris. Id purus placerat risus sem vestibulum phasellus mus sollicitudin. Cursus mattis ullamcorper sodales arcu vulputate. Bibendum proin elementum id consectetur ultrices scelerisque aenean cras. Leo vitae suscipit dui aliquet...'
    },
    {
        title: 'What is a community convention',
        excerpt: 'Libero luctus pharetra porttitor mauris. Id purus placerat risus sem vestibulum phasellus mus sollicitudin. Cursus mattis ullamcorper sodales arcu vulputate. Bibendum proin elementum id consectetur ultrices scelerisque aenean cras. Leo vitae suscipit dui aliquet...'
    },
    {
        title: 'Community convention legal requirements',
        excerpt: 'Libero luctus pharetra porttitor mauris. Id purus placerat risus sem vestibulum phasellus mus sollicitudin. Cursus mattis ullamcorper sodales arcu vulputate. Bibendum proin elementum id consectetur ultrices scelerisque aenean cras. Leo vitae suscipit dui aliquet...'
    },
    {
        title: 'Effective Convention for a Community',
        excerpt: 'Libero luctus pharetra porttitor mauris. Id purus placerat risus sem vestibulum phasellus mus sollicitudin. Cursus mattis ullamcorper sodales arcu vulputate. Bibendum proin elementum id consectetur ultrices scelerisque aenean cras. Leo vitae suscipit dui aliquet...'
    },
    {
        title: 'How to host a community convention',
        excerpt: 'Libero luctus pharetra porttitor mauris. Id purus placerat risus sem vestibulum phasellus mus sollicitudin. Cursus mattis ullamcorper sodales arcu vulputate. Bibendum proin elementum id consectetur ultrices scelerisque aenean cras. Leo vitae suscipit dui aliquet...'
    },
    {
        title: 'What is a community convention',
        excerpt: 'Libero luctus pharetra porttitor mauris. Id purus placerat risus sem vestibulum phasellus mus sollicitudin. Cursus mattis ullamcorper sodales arcu vulputate. Bibendum proin elementum id consectetur ultrices scelerisque aenean cras. Leo vitae suscipit dui aliquet...'
    },
    {
        title: 'Community convention legal requirements',
        excerpt: 'Libero luctus pharetra porttitor mauris. Id purus placerat risus sem vestibulum phasellus mus sollicitudin. Cursus mattis ullamcorper sodales arcu vulputate. Bibendum proin elementum id consectetur ultrices scelerisque aenean cras. Leo vitae suscipit dui aliquet...'
    },
    {
        title: 'Effective Convention for a Community',
        excerpt: 'Libero luctus pharetra porttitor mauris. Id purus placerat risus sem vestibulum phasellus mus sollicitudin. Cursus mattis ullamcorper sodales arcu vulputate. Bibendum proin elementum id consectetur ultrices scelerisque aenean cras. Leo vitae suscipit dui aliquet...'
    },
    {
        title: 'How to host a community convention',
        excerpt: 'Libero luctus pharetra porttitor mauris. Id purus placerat risus sem vestibulum phasellus mus sollicitudin. Cursus mattis ullamcorper sodales arcu vulputate. Bibendum proin elementum id consectetur ultrices scelerisque aenean cras. Leo vitae suscipit dui aliquet...'
    },
    {
        title: 'What is a community convention',
        excerpt: 'Libero luctus pharetra porttitor mauris. Id purus placerat risus sem vestibulum phasellus mus sollicitudin. Cursus mattis ullamcorper sodales arcu vulputate. Bibendum proin elementum id consectetur ultrices scelerisque aenean cras. Leo vitae suscipit dui aliquet...'
    }
]

const dummySearchHistory = [
    'Equity',
    'Community',
    'Convention',
    'Libero',
    'Luctus',
    'Porttitor'
]

const SearchPage = ({ content, title }) => {
    const [searchParams] = useSearchParams();

    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [query, setQuery] = React.useState(searchParams.get('q'));
    const [results, setResults] = React.useState(dummyResults);
    const [matchedWords, setMatchedWords] = React.useState([]);


    const handleChange = (event) => {
        setRowsPerPage(event.target.value);
    };
    const getHighlightedWord = (word, term) => {
        const startIndex = word.toLowerCase().indexOf(term.toLowerCase());
        const beforeMatch = word.slice(0, startIndex);
        const match = word.slice(startIndex, startIndex + term.length);
        const afterMatch = word.slice(startIndex + term.length);

        return {
            beforeMatch,
            match,
            afterMatch,
            word
        };
    };
    const handleClickWord = (event, word) => {
        setQuery(word)
        setMatchedWords([])
    }


    React.useEffect(() => {
        setMatchedWords([])
        if (query !== '') {
            const matches = dummySearchHistory.filter((word) =>
                word.toLowerCase().includes(query.toLowerCase()) && word.toLowerCase() !== query.toLowerCase()
            );
            setMatchedWords(matches.map((word) => getHighlightedWord(word, query)));
        }
    }, [query]);
    return (
        <section className='search' >
            <div className='heading'>
                <h2>
                    Your search results for <span>“Community convention”</span>
                </h2>
                <div className="search-box">
                    <Paper
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
                        className="search-bar"
                    >
                        <form action="/search">
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Search"
                                inputProps={{ 'aria-label': 'search' }}
                                name="q"
                                autoComplete='off'
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                            />
                            {query !== '' &&
                                <IconButton
                                    type="button"
                                    sx={{ p: '10px' }}
                                    onClick={() => setQuery('')}
                                >
                                    <CloseIcon />
                                </IconButton>
                            }
                        </form>
                    </Paper>
                    {matchedWords.length > 0 &&
                        <Paper
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
                            className="search-auto-complete"
                        >
                            <List>
                                {matchedWords.map((word, i) => (
                                    <ListItemButton key={`suggestion-${i}`} onClick={(event) => handleClickWord(event, word.word)}>
                                        <ListItemIcon>
                                            <ScheduleIcon />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {word.beforeMatch}
                                            <b>{word.match}</b>
                                            {word.afterMatch}
                                        </ListItemText>
                                    </ListItemButton>
                                ))}
                            </List>
                        </Paper>
                    }
                </div>
            </div>
            <div className="search-results">
                {results.map((result, i) => (
                    <div className="results-row" key={`result-${i}`}>
                        <h3>
                            {result.title}
                        </h3>
                        <p>
                            {result.excerpt}
                        </p>
                    </div>
                ))}

                <div className="results-row pagination">
                    <span>
                        Rows per page:
                    </span>
                    <FormControl sx={{ m: 1, minWidth: 90 }} size="small">
                        <Select
                            id="demo-simple-select"
                            value={rowsPerPage}
                            label=""
                            onChange={handleChange}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>
                    </FormControl>
                    <span>
                        1-10 of 25
                    </span>
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="pagination previous page">
                        <ChevronLeftOutlinedIcon />
                    </IconButton>
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="pagination next page">
                        <ChevronRightOutlinedIcon />
                    </IconButton>
                </div>
            </div>
        </section>
    );
};

export default SearchPage;
