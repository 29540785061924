// ApiUtils.jsx
import axios from "axios";
import configData from "../../config.json";
import { formatString } from "./stringUtils";
import queryTexts from "../DocChat/queryTexts";
import promptTemplates from "../../data/promptTemplates.json";

const newSessionUrl = configData.DOC_CHAT_SESSION_API + "/newSession";
const fetchSessionListUrl = configData.DOC_CHAT_SESSION_API + "/userSessions";
const fetchSessionUrl = configData.DOC_CHAT_SESSION_API + "/chatSession";
const saveSessionUrl = configData.DOC_CHAT_SESSION_API + "/chatSession";
const baseGetUploadUrl = configData.UPLOAD_DOC_API;
const docChatUrl = configData.CLAUDE_DOC_CHAT_URL + "/doc-chat";
const usageApiUrl = configData.USAGE_API;
const createLensUrl = configData.DOC_CHAT_SESSION_API + "/lenses/{userId}";
const getLensesUrl = configData.DOC_CHAT_SESSION_API + "/lenses/{userId}";
const updateLensUrl =
  configData.DOC_CHAT_SESSION_API + "/lenses/{userId}/{lensId}";
const deleteLensUrl =
  configData.DOC_CHAT_SESSION_API + "/lenses/{userId}/{lensId}";
const apiKey = configData.API_KEY;
const templateMap = promptTemplates.prompt_templates.reduce((map, template) => {
  map[template.id] = template.template;
  return map;
}, {});

class ApiUtils {
  static async incrementUsage(
    userId,
    accessToken,
    fileIncrement = false,
    queryIncrement = false
  ) {
    try {
      const url = `${usageApiUrl}?userId=${encodeURIComponent(userId)}`;
      const params = new URLSearchParams();
      if (fileIncrement) params.append("file", "");
      if (queryIncrement) params.append("query", "");
      const response = await axios.post(`${url}&${params.toString()}`, {
        headers: {
          "x-api-key": apiKey,
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 400) {
        throw new Error(response.data);
      }
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getUploadUrlForFile(file, accessToken, session_Id) {
    const url = `${baseGetUploadUrl}?sessionId=${session_Id}&file=${file.name}`;
    const response = await axios.get(url, {
      headers: {
        "x-api-key": configData.API_KEY,
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  }

  static uploadFileToURL(url, data) {
    return axios.put(url, { data }, { headers: { "Content-Type": "" } });
  }

  static async sendDocumentSummary(
    sessionID,
    file,
    newSession,
    accessToken,
    lens = "",
    brainstorm
  ) {
    const queryInfo = { filename: file.name };
    const query = formatString(queryTexts.documentSummaryQuery, queryInfo);
    const promptTemplate = templateMap["context_prompt"];
    const response = await this.sendChatQuery(
      sessionID,
      query,
      [file.name],
      newSession,
      accessToken,
      promptTemplate,
      lens,
      brainstorm
    );
    return response;
  }

  static async sendDocumentChatQuery(
    sessionID,
    userQuery,
    filenames,
    newSession,
    accessToken,
    lens = "",
    brainstorm
  ) {
    const queryInfo = { filenames: filenames, userQuery: userQuery };
    const query = formatString(queryTexts.documentQuery, queryInfo);
    const promptTemplate = templateMap["context_prompt"];
    const response = await this.sendChatQuery(
      sessionID,
      query,
      filenames,
      newSession,
      accessToken,
      promptTemplate,
      lens,
      brainstorm
    );
    return response;
  }

  static async sendChatQuery(
    sessionID,
    query,
    filenames,
    newSession,
    accessToken,
    promptTemplate,
    lens = "",
    brainstorm
  ) {
    const systemPrompt = lens;
    const prepedPromptTemplate = (promptTemplate = !promptTemplate
      ? templateMap["no_context_prompt"]
      : promptTemplate);
    const brainstormValue = brainstorm;
    const finalPromptTemplate = combinePrompts(
      systemPrompt,
      prepedPromptTemplate
    );
    try {
      const response = await axios.post(
        docChatUrl,
        {
          sessionID: sessionID,
          query: query,
          filenames: filenames,
          newSession: newSession,
          promptTemplate: finalPromptTemplate,
          brainstorm: brainstormValue,
        },
        {
          headers: {
            "x-api-key": configData.CLAUDE_DOC_CHAT_API_KEY,
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async initiateNewSession(user, query, accessToken) {
    const sessionName = query.substring(0, 100);
    const url = `${newSessionUrl}?userId=${encodeURIComponent(
      user.sub
    )}&sessionName=${encodeURIComponent(sessionName)}`;
    const response = await axios.get(url, {
      headers: {
        "x-api-key": apiKey,
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  }

  //Chat history Functions
  static async fetchChatHistoryList(user, accessToken) {
    const url = `${fetchSessionListUrl}?userId=${encodeURIComponent(user.sub)}`;
    const response = await axios.get(url, {
      headers: {
        "x-api-key": apiKey,
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  }

  static async loadChatSession(sessionId, accessToken) {
    const url = `${fetchSessionUrl}?sessionId=${sessionId}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "x-api-key": apiKey,
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async saveChatSession(sessionData, accessToken) {
    const { sessionId, documents, chatMessages, isNewSession, filesInfo } =
      sessionData;
    const url = `${saveSessionUrl}?sessionId=${sessionId}`;

    try {
      const response = await axios.post(
        url,
        {
          documents,
          chatMessages,
          filesInfo,
        },
        {
          headers: {
            "x-api-key": apiKey,
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async createLens(userId, lensData, accessToken) {
    const url = createLensUrl.replace("{userId}", userId);
    try {
      const response = await axios.post(url, lensData, {
        headers: {
          "x-api-key": apiKey,
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getLenses(userId, accessToken) {
    const url = getLensesUrl.replace("{userId}", userId);
    try {
      const response = await axios.get(url, {
        headers: {
          "x-api-key": apiKey,
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async updateLens(userId, lensId, lensData, accessToken) {
    const url = updateLensUrl
      .replace("{userId}", userId)
      .replace("{lensId}", lensId);
    try {
      const response = await axios.put(url, lensData, {
        headers: {
          "x-api-key": apiKey,
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async deleteLens(userId, lensId, accessToken) {
    const url = deleteLensUrl
      .replace("{userId}", userId)
      .replace("{lensId}", lensId);
    try {
      const response = await axios.delete(url, {
        headers: {
          "x-api-key": apiKey,
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

const combinePrompts = (systemPrompt, promptTemplate) => {
  // Remove leading/trailing whitespace from the system prompt and prompt template
  const trimmedSystemPrompt = systemPrompt.trim();
  const trimmedPromptTemplate = promptTemplate.trim();

  // Combine the system prompt and prompt template
  const combinedPrompt = `
${trimmedSystemPrompt}

${trimmedPromptTemplate}
`;

  return combinedPrompt;
};
export default ApiUtils;
