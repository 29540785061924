import { useAuth0 } from "@auth0/auth0-react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  Button,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";

const BasicProfile = () => {
  const { user } = useAuth0();
  const [role, setRole] = React.useState("");
  const [username, setUsername] = React.useState(user.nickname);
  const [email, setEmail] = React.useState(user.email);
  const [sector, setSector] = React.useState("");
  const [stateAreaRegion, setStateAreaRegion] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [profileImageFile, setProfileImageFile] = React.useState(null);

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };
  const handleSectorChange = (event) => {
    setSector(event.target.value);
  };
  const handleStateAreaRegionChange = (event) => {
    setStateAreaRegion(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (!files) return;
    setProfileImageFile(files[0]);
  };

  return (
    <div className="basic-profile">
      <h2>Personal Information</h2>
      <div className="profile-fields">
        <TextField
          variant="filled"
          label="Username"
          value={username}
        ></TextField>
        <TextField variant="filled" label="First Name"></TextField>
        <TextField variant="filled" label="Last Name"></TextField>
        <TextField
          type="email"
          variant="filled"
          label="Email Address"
          value={email}
        ></TextField>
        <TextField variant="filled" label="Organization"></TextField>
        <FormControl variant="filled">
          <InputLabel id="role-label">Role</InputLabel>
          <Select
            labelId="role-label"
            id="role"
            value={role}
            onChange={handleRoleChange}
          >
            <MenuItem value="">
              <em>Choose one...</em>
            </MenuItem>
            <MenuItem value="Programs">Programs</MenuItem>
            <MenuItem value="Evaluation">Evaluation</MenuItem>
            <MenuItem value="Community Engagement">
              Community Engagement
            </MenuItem>
            <MenuItem value="Development">Development</MenuItem>
            <MenuItem value="Operations">Operations</MenuItem>
            <MenuItem value="Technology">Technology</MenuItem>
            <MenuItem value="Policy">Policy</MenuItem>
            <MenuItem value="Communications">Communications</MenuItem>
            <MenuItem value="Executive">Executive</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="filled">
          <InputLabel id="sector-label">Sector</InputLabel>
          <Select
            labelId="sector-label"
            id="sector"
            value={sector}
            onChange={handleSectorChange}
          >
            <MenuItem value="">
              <em>Choose one...</em>
            </MenuItem>
            <MenuItem value="Nonprofit">Nonprofit</MenuItem>
            <MenuItem value="Foundation">Foundation</MenuItem>
            <MenuItem value="Community Group">Community Group</MenuItem>
            <MenuItem value="Government Agency">Government Agency</MenuItem>
            <MenuItem value="Academia">Academia</MenuItem>
            <MenuItem value="Research">Research</MenuItem>
            <MenuItem value="Business">Business</MenuItem>
            <MenuItem value="Individual">Individual</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="filled">
          <InputLabel htmlFor="prompt-query">Profile Picture</InputLabel>
          <FilledInput
            id="prompt-query"
            type="text"
            value={profileImageFile ? profileImageFile.name : ""}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="upload profile image file"
                  edge="end"
                  component="label"
                  color="primary"
                >
                  <FileUploadIcon />
                  <input
                    type="file"
                    id="profileImageFile"
                    name="profileImageFile"
                    accept=".jpg,.png,.bmp"
                    onChange={handleFileChange}
                  />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
      <h2>Location</h2>
      <div className="profile-fields">
        <TextField variant="filled" label="City"></TextField>
        <FormControl variant="filled">
          <InputLabel id="state-area-region-label">
            State / Area / Region
          </InputLabel>
          <Select
            labelId="state-area-region-label"
            id="state-area-region"
            value={stateAreaRegion}
            onChange={handleStateAreaRegionChange}
          >
            <MenuItem value="">
              <em>Choose one...</em>
            </MenuItem>
            <MenuItem value="AK - Alaska">AK - Alaska</MenuItem>
            <MenuItem value="State B">State B</MenuItem>
            <MenuItem value="State C">State C</MenuItem>
          </Select>
        </FormControl>
        <TextField variant="filled" label="Zip Code"></TextField>
        <FormControl variant="filled">
          <InputLabel id="country-label">Country</InputLabel>
          <Select
            labelId="country-label"
            id="country"
            value={country}
            onChange={handleCountryChange}
          >
            <MenuItem value="">
              <em>Choose one...</em>
            </MenuItem>
            <MenuItem value="United States">United States</MenuItem>
            <MenuItem value="Country B">Country B</MenuItem>
            <MenuItem value="Country C">Country C</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="buttons">
        <Button>Cancel</Button>
        <Button variant="contained">Update</Button>
      </div>
    </div>
  );
};

export default BasicProfile;
