import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, MenuItem } from '@mui/material';
import Menu from "@mui/material/Menu";
import React from 'react';
import { useNavigate } from "react-router-dom";

const NavButtonChildren = ({ page }) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                key={page.label}
                endIcon={<ExpandMoreIcon />}
                sx={{
                    display: "flex",
                    textTransform: "none",
                }}
                onClick={handleClick}
            >{page.label}
            </Button>
            <Menu
                id="nav-children-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': `${page.label} menu`,
                }}
            >
                {page.children.map(item => (
                    <MenuItem onClick={() => navigate(item.path)}>
                        {item.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default NavButtonChildren;
