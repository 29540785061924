import BorderColorIcon from '@mui/icons-material/BorderColor';
import {
    ClickAwayListener,
    IconButton,
    ListItem,
    ListItemButton,
    TextField,
    Typography
} from '@mui/material';
import * as React from 'react';

export default function LabelEditableListItem({
    onClick,
    onChange,
    item
}) {
    const { name } = item

    const [editing, setEditing] = React.useState(false)
    const [value, setValue] = React.useState(name);
    const toggleEdit = () => {
        setEditing(currentValue => !currentValue);
    }
    const handleEditTitle = () => {
        if (onChange)
            onChange(item, value)

        setEditing(false)
    }
    const handleClickAway = () => {
        setEditing(false)
    }
    React.useEffect(() => {
        setValue(item.name)
    }, [item])

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <ListItem className="label-editable-list-item" disablePadding>
                {!editing ?
                    <>
                        <ListItemButton onClick={() => onClick(item)}>
                            <Typography sx={{
                                wordBreak: 'break-word',
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                WebkitLineClamp: "1",
                                WebkitBoxOrient: "vertical",
                            }}>
                                {name}
                            </Typography>
                            <IconButton onClick={toggleEdit} size="small" >
                                {/** Comment out until editable 
                                <BorderColorIcon />
                                */}
                            </IconButton>
                        </ListItemButton>

                    </>
                    :
                    <TextField
                        value={value}
                        variant="outlined"
                        size="small"
                        onChange={(e) => setValue(e.target.value)}
                        autoFocus
                        onKeyDown={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                                e.preventDefault();
                                handleEditTitle();
                            }
                        }}
                    />
                }
            </ListItem>
        </ClickAwayListener>
    );

};