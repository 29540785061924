import React from "react";
import { FormControl, InputLabel, TextField, Button } from "@mui/material";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PriorityAudienceLens = ({
  audienceName,
  setAudienceName,
  audienceDescription,
  setAudienceDescription,
  audienceCharacteristics,
  setAudienceCharacteristics,
  audienceLikes,
  setAudienceLikes,
  audienceDislikes,
  setAudienceDislikes,
  audienceInteractions,
  setAudienceInteractions,
  activeStep,
  nextStep,
  prevStep,
  onFinish,
  isEditing = false,
  title,
  setTitle,
  description,
  setDescription,
  authors,
  setAuthors,
}) => {
  const navigate = useNavigate();
  return (
    <>
      {activeStep === 1 && (
        <>
          <p>
            Which audiences and groups should be taken into consideration when
            generating the response?
          </p>
          <Typography className="footer-message">
            <p>Please note:</p>
            <p>
              Efficacy of Lenses is dependent on the information used to create
              the lens as well as the subsequent content and questions it is
              used in conjunction with. Lenses are not guaranteed to change AI
              PRIORI's responses.
            </p>
          </Typography>
          <div className="priority-audience">
            <FormControl className="top-labeled">
              <InputLabel shrink htmlFor="lens-title-input">
                Lens Title
              </InputLabel>
              <TextField
                required
                placeholder="Enter a title for your lens"
                id="lens-title-input"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormControl>
            <FormControl className="top-labeled">
              <InputLabel shrink htmlFor="lens-description-input">
                Lens Description
              </InputLabel>
              <TextField
                multiline
                required
                rows={2}
                placeholder="Provide a brief description of your lens"
                id="lens-description-input"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>
            <FormControl className="top-labeled">
              <InputLabel shrink htmlFor="lens-authors-input">
                Authors
              </InputLabel>
              <TextField
                required
                placeholder="Enter author names (comma-separated)"
                id="lens-authors-input"
                value={authors}
                onChange={(e) => setAuthors(e.target.value)}
              />
            </FormControl>
            <FormControl className="top-labeled">
              <InputLabel shrink htmlFor="audience-name-input">
                Audience Name:
                {/*<span className="required">*</span>*/}
              </InputLabel>
              <TextField
                required
                placeholder="Write here"
                id="audience-name-input"
                value={audienceName}
                onChange={(e) => setAudienceName(e.target.value)}
              />
            </FormControl>
            <FormControl className="top-labeled">
              <InputLabel shrink htmlFor="audience-description-input">
                Audience Description:
                {/*<span className="required">*</span>*/}
              </InputLabel>
              <TextField
                multiline
                required
                rows={4}
                placeholder="Write here"
                id="audience-description-input"
                value={audienceDescription}
                onChange={(e) => setAudienceDescription(e.target.value)}
              />
            </FormControl>
          </div>
          <div className="actions">
            {isEditing ? (
              <>
                <Button onClick={() => navigate("/lenses")}>Cancel</Button>
              </>
            ) : (
              <>
                <Button onClick={prevStep}>Back</Button>
              </>
            )}
            <Button variant="contained" onClick={nextStep}>
              Next
            </Button>
          </div>
        </>
      )}
      {activeStep === 2 && (
        <>
          <div className="priority-audience">
            <FormControl className="top-labeled">
              <InputLabel shrink htmlFor="audience-characteristics-input">
                Audience Characteristics:
                {/*<span className="required">*</span>*/}
              </InputLabel>
              <TextField
                multiline
                required
                rows={4}
                placeholder="Write here"
                id="audience-characteristics-input"
                value={audienceCharacteristics}
                onChange={(e) => setAudienceCharacteristics(e.target.value)}
              />
            </FormControl>
            <FormControl className="top-labeled">
              <InputLabel shrink htmlFor="audience-likes-input">
                Audience Likes:
                {/*<span className="required">*</span>*/}
              </InputLabel>
              <TextField
                multiline
                required
                rows={4}
                placeholder="Write here"
                id="audience-likes-input"
                value={audienceLikes}
                onChange={(e) => setAudienceLikes(e.target.value)}
              />
            </FormControl>
            <FormControl className="top-labeled">
              <InputLabel shrink htmlFor="audience-dislikes-input">
                Audience Dislikes:
                {/*<span className="required">*</span>*/}
              </InputLabel>
              <TextField
                multiline
                required
                rows={4}
                placeholder="Write here"
                id="audience-dislikes-input"
                value={audienceDislikes}
                onChange={(e) => setAudienceDislikes(e.target.value)}
              />
            </FormControl>
            <FormControl className="top-labeled">
              <InputLabel shrink htmlFor="audience-interactions-input">
                How Does Your Organization and Members of this Audience Interact
                with One Another?{/*<span className="required">*</span>*/}
              </InputLabel>
              <TextField
                multiline
                required
                rows={4}
                placeholder="Write here"
                id="audience-interactions-input"
                value={audienceInteractions}
                onChange={(e) => setAudienceInteractions(e.target.value)}
              />
            </FormControl>
          </div>
          <div className="actions">
            <Button onClick={prevStep}>Back</Button>
            <Button variant="contained" onClick={onFinish}>
              {isEditing ? "Update" : "Create"} lens
            </Button>
          </div>
        </>
      )}
    </>
  );
};
export default PriorityAudienceLens;
