/** 
*Use:
*  Setup info object
    const queryInfo = {
      filename: file.name, 
    };
*  format string with info object 
    const query = formatString(queryTexts.documentSummaryQuery, queryInfo); 
*
* This will replace any instaces of {filename} with the file.name variable
*/
export function formatString(template, values) {
  return template.replace(/{(\w+)}/g, (placeholder, key) => {
    return key in values ? values[key] : placeholder;
  });
}
